import React, { useRef } from 'react';
import { CompleteParagraphVideoFieldsFragment } from '../../../graphql-types';
import RenderVideo from '../video/renderVideo';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faVolumeUp, faVolumeXmark } from '@fortawesome/free-solid-svg-icons';

interface Props {
  index: number;
  videoParagraph: CompleteParagraphVideoFieldsFragment;
}

const Video: React.FC<Props> = ({ index, videoParagraph }) => {
  const [isMuted, setIsMuted] = React.useState<boolean | null>(null);
  const videoDesktopRef = useRef<HTMLVideoElement>();
  const videoMobileRef = useRef<HTMLVideoElement>();

  const desktopVideo =
    videoParagraph.relationships?.field_video?.relationships?.field_media_video_file?.localFile
      ?.publicURL;
  const mobileVideo =
    videoParagraph.relationships?.field_video_mobile?.relationships?.field_media_video_file
      ?.localFile?.publicURL;

  const handleToggleSound = (videoRef: React.MutableRefObject<HTMLVideoElement | undefined>) => {
    if (videoRef.current && window.getComputedStyle(videoRef.current).display !== 'none') {
      if (videoRef.current.muted) {
        videoRef.current.muted = false;
      } else {
        videoRef.current.muted = true;
      }
    }
  };

  return (
    <div className="relative">
      <RenderVideo
        forwardedDesktopRef={videoDesktopRef}
        forwardedMobileRef={videoMobileRef}
        videoClassName="w-full"
        video={desktopVideo}
        videoMobile={mobileVideo}
        loop
      />
      <div
        className="absolute bottom-0 right-0 pr-3 pb-3 tablet:pr-6 tablet:pb-6 cursor-pointer"
        onClick={() => {
          handleToggleSound(videoDesktopRef);
          handleToggleSound(videoMobileRef);
          setIsMuted(!isMuted);
        }}
      >
        <div className="w-10">
          {isMuted ? (
            <FontAwesomeIcon icon={faVolumeUp} size="2x" />
          ) : (
            <FontAwesomeIcon icon={faVolumeXmark} size="2x" />
          )}
        </div>
      </div>
    </div>
  );
};

export default Video;
