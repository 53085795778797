import React from 'react';
import ToutTextSimple from './types/toutTextSimple';
import ToutTextLink from './types/toutTextLink';
import ToutTextImageLink from './types/toutTextImageLink';
import ToutTextSimpleDuet from './types/toutTextSimpleDuet';
import { ToutTextProps } from './props';

const backgroundColor: Record<string, string> = {
  'none': '',
  'black': 'bg-black text-white',
  'orange': 'bg-orange-600',
};

const titleColor: Record<string, string> = {
  'none': '',
  'yellow': 'text-yellow-550',
};

enum TextToutTypes {
  'simple' = 'simple',
  'simpleDuet' = 'simpleDuet',
  'link' = 'link',
  'imageLink' = 'imageLink',
}

const TextTout: React.FC<ToutTextProps> = ({ toutTextItem }) => {
  const params: ToutTextProps = {
    toutTextItem,
    backgroundClass:
      backgroundColor[toutTextItem?.field_tout_text_color ?? 'none'] ?? backgroundColor.none,
    titleClass: titleColor[toutTextItem.field_tout_text_title_color ?? 'none'] ?? '',
  };

  switch (toutTextItem.field_text_tout_type) {
    case TextToutTypes.simple:
      return <ToutTextSimple {...params} />;
    case TextToutTypes.simpleDuet:
      return <ToutTextSimpleDuet {...params} />;
    case TextToutTypes.link:
      return <ToutTextLink {...params} />;
    case TextToutTypes.imageLink:
      return <ToutTextImageLink {...params} />;
    default:
      break;
  }
  return <></>;
};

export default TextTout;
