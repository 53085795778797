import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import { CompleteBodyContentFieldsFragment } from '../../graphql-types';
import { transformBody } from '../pageBuilders/articleBuilder';

interface Props {
  bodyContentItem?: CompleteBodyContentFieldsFragment['nodes'][0];
}
const BodyContent: React.FC<Props> = ({ bodyContentItem }) => {
  if (!bodyContentItem?.body?.processed) {
    return null;
  }
  return (
    <div className="mobile:pt-25 tablet:pt-45 pb-15">
      {ReactHtmlParser(bodyContentItem.body.processed, {
        transform: transformBody,
      })}
    </div>
  );
};

export default BodyContent;
