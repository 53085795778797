import React from 'react';
import { CompleteDownloadableContentFieldsFragment } from '../../graphql-types';
import RenderImage from './article/renderImage';

interface Props {
  downloadableItem: CompleteDownloadableContentFieldsFragment['nodes'][0];
}

function encodeFileName(fileURL: string) {
  const URLarray = fileURL?.split('/');
  URLarray[URLarray.length - 1] = encodeURIComponent(URLarray[URLarray.length - 1]);
  return URLarray.join('/');
}

const DownloadableContent: React.FC<Props> = ({ downloadableItem }) => {
  const title = downloadableItem.title;
  const image = downloadableItem.relationships?.field_image;
  const fileURL = downloadableItem.relationships?.field_file?.localFile?.publicURL;
  if (!fileURL) {
    return null;
  }

  return (
    <div className="container max-width">
      <div className="laptop-standard:px-40 mobile:px-10 laptop-large:px-56 laptop-wide:px-64 desktop-standard:px-65 desktop-large:px-74 laptop-standard:block pb-27">
        <a href={encodeFileName(fileURL)} target="_blank" rel="noreferrer">
          <b>{title}</b>
          {image && (
            <RenderImage
              className="pt-6 pb-2 laptop-standard:w-4/12 "
              image={
                image?.relationships?.field_media_image?.localFile?.childImageSharp?.gatsbyImageData
              }
              imgAlt={image?.field_media_image?.alt ?? ''}
            />
          )}
        </a>
      </div>
    </div>
  );
};

export default DownloadableContent;
