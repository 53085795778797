import React, { useEffect, useState } from 'react';
import Masthead from '../components/masthead';
import NewsGrid from '../components/news/newsGrid';
import Tout from '../components/tout';
import ArticlesGrid from '../components/article/articlesGrid';
import ContentAdvertise from '../components/contentAdvertise';
import Carousel from '../components/carousel';

import ToutText from '../components/toutText';
import {
  GetHomeDataQuery,
  GetInsightsDataQuery,
  GetNewsDataQuery,
  GetAboutUsDataQuery,
  GetLandingPageDataQuery,
  CompleteMastheadsFieldsFragment,
  CompleteToutFieldsFragment,
  Complete2upGridFieldsFragment,
  CompleteNewsGridFieldsFragment,
  CompleteArticlesGridFieldsFragment,
  CompleteToutTextFieldsFragment,
  GetProjectsDataQuery,
  CompleteContentAdvertiseFieldsFragment,
  CompleteQuoteFieldsFragment,
  CompleteToutSimpleFieldsFragment,
  CompleteSimpleMediaFieldsFragment,
  CompleteCarouselFieldsFragment,
  CompleteArticleToutFieldsFragment,
  CompleteBodyContentFieldsFragment,
  CompleteDownloadableContentFieldsFragment,
  CompleteTout5050FieldsFragment,
  CompleteMasthead2022FieldsFragment,
  Complete3UpInformativeFieldsFragment,
  CompleteMediaGridFieldsFragment,
  CompleteBodyBuilderItemsFragment,
  CompleteQuickLinksFieldsFragment,
  GetWorkDataQuery,
} from '../../graphql-types';
import Quote from '../components/quote';
import ToutSimple from '../components/toutSimple';
import SimpleMedia from '../components/SimpleMedia';
import TwoUpGrid from '../components/twoUp/twoUpGrid';
import ArticleTout from '../components/article/articleTout';
import BodyContent from '../components/bodyContent';
import DownloadableContent from '../components/downloadableContent';
import Tout5050 from '../components/tout5050';
import Masthead2022 from '../components/masthead2022';
import Button from '../components/button';

import DownArrow from '../images/icons/DownArrow.svg';
import ThreeUpInformative from '../components/threeUpInformative';
import MediaGrid from '../components/mediaGrid';
import PageBuilder from './pageBuilder';
import QuickLinks from '../components/quickLinks';

enum RenderedType {
  'BodyBuilder' = 'BodyBuilder',
  'Masthead' = 'Masthead',
  'Masthead2022' = 'Masthead2022',
  'Tout' = 'Tout',
  'ToutSimple' = 'ToutSimple',
  'TwoUpGrid' = 'TwoUpGrid',
  'ThreeUpInformative' = 'ThreeUpInformative',
  'ToutText' = 'ToutText',
  'SimpleMedia' = 'SimpleMedia',
  'MediaGrid' = 'MediaGrid',
  'ContentAdvertise' = 'ContentAdvertise',
  'Quote' = 'Quote',
  'Carousel' = 'Carousel',
  'ArticleTout' = 'ArticleTout',
  'BodyContent' = 'BodyContent',
  'DownloadableContent' = 'DownloadableContent',
  'QuickLinks' = 'QuickLinks',
  'Tout5050' = 'Tout5050',
  'NewsGrid' = 'NewsGrid',
  'ArticlesGrid' = 'ArticlesGrid',
}
const Separator = <div className="pb-25" />;

const SeparatorExceptions = [RenderedType.ToutText, RenderedType.ToutSimple, RenderedType.Tout5050];

type ItemData =
  | CompleteMastheadsFieldsFragment['nodes'][0]
  | CompleteToutFieldsFragment['nodes'][0]
  | Complete2upGridFieldsFragment['nodes'][0]
  | CompleteToutFieldsFragment
  | CompleteNewsGridFieldsFragment
  | CompleteArticlesGridFieldsFragment
  | CompleteArticleToutFieldsFragment
  | CompleteBodyContentFieldsFragment
  | CompleteDownloadableContentFieldsFragment
  | CompleteQuickLinksFieldsFragment
  | CompleteTout5050FieldsFragment
  | CompleteMasthead2022FieldsFragment
  | Complete3UpInformativeFieldsFragment
  | CompleteMediaGridFieldsFragment
  | CompleteBodyBuilderItemsFragment;

type RenderedComponents = Record<number, { renderedType: RenderedType; item: ItemData }>;

interface Props {
  id: string;
  data:
    | GetHomeDataQuery
    | GetInsightsDataQuery
    | GetNewsDataQuery
    | GetAboutUsDataQuery
    | GetWorkDataQuery
    | GetProjectsDataQuery
    | GetLandingPageDataQuery;
  temporalRenderComponent?: Record<number, React.ReactNode>;
  className?: string;
  loadMore?: boolean;
}

const loadMoreStep = 3;
const loadMoreExceptions = [RenderedType.NewsGrid];

const AutoOrder: React.FC<Props> = ({ id, data, temporalRenderComponent, className, loadMore }) => {
  const [renderedComponents, setRenderedComponents] = useState<RenderedComponents>({});

  const [loadMoreIndex, setLoadMoreIndex] = useState<number>(loadMoreStep);

  useEffect(() => {
    const bodyBuilderItemsData = data.allNodeBodyBuilder?.nodes;
    const mastheadData = data.allNodeMasthead?.nodes;
    const masthead2022Data = data.allNodeMasthead2022?.nodes;
    const toutData = data.allNodeTout?.nodes;
    const twoUps = data.allNode2UpGrid?.nodes;
    const threeUpInformative = data.allNode3UpInformative?.nodes;
    const toutTextData = data.allNodeToutText?.nodes;
    const contentAdvertiseData = data.allNodeContentAdvertise?.nodes;
    const quoteData = data.allNodeQuote?.nodes;
    const toutSimpleData = data.allNodeToutSimple?.nodes;
    const simpleMediaData = data.allNodeSimpleMedia?.nodes;
    const mediaGridData = data.allNodeMediaGrid?.nodes;
    const carouselData = data.allNodeCarousel?.nodes;
    const articleTout = data.allNodeArticleTout?.nodes;
    const bodyContentData = data.allNodeBodyContent?.nodes;
    const downloadableContentData = data.allNodeDownloadableContent?.nodes;
    const quickLinksData = data.allNodeQuickLi?.nodes;

    const tout5050Data = data.allNodeTout5050?.nodes;

    const newsGridData = data.allNodeNewsGrid?.nodes;
    const articlesGridData = data.allNodeArticlesGrid?.nodes;

    const renderedObjects: RenderedComponents = {};

    bodyBuilderItemsData?.forEach((bodyBuilderItem) => {
      if (bodyBuilderItem.field_page_presentation_order) {
        renderedObjects[bodyBuilderItem.field_page_presentation_order] = {
          renderedType: RenderedType.BodyBuilder,
          item: bodyBuilderItem,
        };
      }
    });

    mastheadData?.forEach((mastheadItem) => {
      if (mastheadItem.field_page_presentation_order) {
        renderedObjects[mastheadItem.field_page_presentation_order] = {
          renderedType: RenderedType.Masthead,
          item: mastheadItem,
        };
      }
    });

    masthead2022Data?.forEach((masthead2022Item) => {
      if (masthead2022Item.field_page_presentation_order) {
        renderedObjects[masthead2022Item.field_page_presentation_order] = {
          renderedType: RenderedType.Masthead2022,
          item: masthead2022Item,
        };
      }
    });

    toutData?.forEach((toutItem) => {
      if (toutItem.field_page_presentation_order) {
        renderedObjects[toutItem.field_page_presentation_order] = {
          renderedType: RenderedType.Tout,
          item: toutItem,
        };
      }
    });

    twoUps?.forEach((twoUpsItems) => {
      if (twoUpsItems.field_page_presentation_order) {
        renderedObjects[twoUpsItems.field_page_presentation_order] = {
          renderedType: RenderedType.TwoUpGrid,
          item: twoUpsItems,
        };
      }
    });

    threeUpInformative?.forEach((threeUpInformativeItems) => {
      if (threeUpInformativeItems.field_page_presentation_order) {
        renderedObjects[threeUpInformativeItems.field_page_presentation_order] = {
          renderedType: RenderedType.ThreeUpInformative,
          item: threeUpInformativeItems,
        };
      }
    });

    toutTextData?.forEach((toutTextItem) => {
      if (toutTextItem.field_page_presentation_order) {
        renderedObjects[toutTextItem.field_page_presentation_order] = {
          renderedType: RenderedType.ToutText,
          item: toutTextItem,
        };
      }
    });

    contentAdvertiseData?.forEach((contentAdItem) => {
      if (contentAdItem.field_page_presentation_order) {
        renderedObjects[contentAdItem.field_page_presentation_order] = {
          renderedType: RenderedType.ContentAdvertise,
          item: contentAdItem,
        };
      }
    });

    quoteData?.forEach((quoteItem) => {
      if (quoteItem.field_page_presentation_order) {
        renderedObjects[quoteItem.field_page_presentation_order] = {
          renderedType: RenderedType.Quote,
          item: quoteItem,
        };
      }
    });

    toutSimpleData?.forEach((toutSimpleItem) => {
      if (toutSimpleItem.field_page_presentation_order) {
        renderedObjects[toutSimpleItem.field_page_presentation_order] = {
          renderedType: RenderedType.ToutSimple,
          item: toutSimpleItem,
        };
      }
    });

    simpleMediaData?.forEach((simpleMediaItem) => {
      if (simpleMediaItem.field_page_presentation_order) {
        renderedObjects[simpleMediaItem.field_page_presentation_order] = {
          renderedType: RenderedType.SimpleMedia,
          item: simpleMediaItem,
        };
      }
    });

    mediaGridData?.forEach((mediaGridItem) => {
      if (mediaGridItem.field_page_presentation_order) {
        renderedObjects[mediaGridItem.field_page_presentation_order] = {
          renderedType: RenderedType.MediaGrid,
          item: mediaGridItem,
        };
      }
    });

    carouselData?.forEach((carouselItem) => {
      if (carouselItem.field_page_presentation_order) {
        renderedObjects[carouselItem.field_page_presentation_order] = {
          renderedType: RenderedType.Carousel,
          item: carouselItem,
        };
      }
    });

    articleTout?.forEach((article) => {
      if (article.field_page_presentation_order) {
        renderedObjects[article.field_page_presentation_order] = {
          renderedType: RenderedType.ArticleTout,
          item: article,
        };
      }
    });

    bodyContentData?.forEach((bodyContent) => {
      if (bodyContent.field_page_presentation_order) {
        renderedObjects[bodyContent.field_page_presentation_order] = {
          renderedType: RenderedType.BodyContent,
          item: bodyContent,
        };
      }
    });

    downloadableContentData?.forEach((downloadableContent) => {
      if (downloadableContent.field_page_presentation_order) {
        renderedObjects[downloadableContent.field_page_presentation_order] = {
          renderedType: RenderedType.DownloadableContent,
          item: downloadableContent,
        };
      }
    });

    quickLinksData?.forEach((quickLink) => {
      if (quickLink.field_page_presentation_order) {
        renderedObjects[quickLink.field_page_presentation_order] = {
          renderedType: RenderedType.QuickLinks,
          item: quickLink,
        };
      }
    });

    tout5050Data?.forEach((tout5050) => {
      if (tout5050.field_page_presentation_order) {
        renderedObjects[tout5050.field_page_presentation_order] = {
          renderedType: RenderedType.Tout5050,
          item: tout5050,
        };
      }
    });

    newsGridData?.forEach((newsGrid) => {
      if (newsGrid.field_page_presentation_order) {
        renderedObjects[newsGrid.field_page_presentation_order] = {
          renderedType: RenderedType.NewsGrid,
          item: newsGrid,
        };
      }
    });

    articlesGridData?.forEach((articlesGrid) => {
      if (articlesGrid.field_page_presentation_order) {
        renderedObjects[articlesGrid.field_page_presentation_order] = {
          renderedType: RenderedType.ArticlesGrid,
          item: articlesGrid,
        };
      }
    });

    setRenderedComponents(renderedObjects);
  }, [data]);

  const getComponentsByType = React.useCallback(
    (renderedType: RenderedType) => {
      return Object.keys(renderedComponents)
        .map(
          (componentOrder) =>
            renderedComponents[componentOrder as unknown as number].renderedType === renderedType &&
            renderedComponents[componentOrder as unknown as number].item
        )
        .filter((item) => item);
    },
    [renderedComponents]
  );

  const loadMoreBeforeIndex = loadMoreIndex - 1;
  const loadMoreAfterIndex = loadMoreIndex + 1;

  return (
    <div className={className}>
      {Object.keys(renderedComponents).map((indexString, positionIndex) => {
        const index = indexString as unknown as number;
        if (!renderedComponents[index]) {
          return null;
        }

        const classNameSeparator = `py-12 ${
          loadMore ? (loadMoreBeforeIndex === positionIndex ? 'pb-0' : '') : ''
        }${loadMore ? (loadMoreAfterIndex === positionIndex ? 'pt-0' : '') : ''}`;

        let renderedItem = <></>;

        const itemToRender = renderedComponents[index];

        if (
          loadMore &&
          !loadMoreExceptions.find((exception) => exception === itemToRender.renderedType)
        ) {
          if (positionIndex === loadMoreIndex) {
            return (
              <Button
                key="load-more-page"
                contentType="loadItems"
                icon={DownArrow}
                handleClick={() => setLoadMoreIndex((value) => value + loadMoreStep)}
                linkTitle="Load More"
                styles="container mx-auto w-40 py-15 laptop-standard:py-25"
              />
            );
          }
          if (positionIndex > loadMoreIndex) {
            return null;
          }
        }

        switch (itemToRender.renderedType) {
          case RenderedType.BodyBuilder:
            renderedItem = (
              <PageBuilder
                key={`pb-${id}-${index}`}
                id={id}
                items={itemToRender.item as CompleteBodyBuilderItemsFragment['nodes'][0]}
                position={index}
              />
            );
            break;
          case RenderedType.Masthead:
            renderedItem = (
              <Masthead
                key={`masthead-${id}-${index}`}
                mastheadData={itemToRender.item as CompleteMastheadsFieldsFragment['nodes'][0]}
              />
            );
            break;
          case RenderedType.Masthead2022:
            renderedItem = (
              <Masthead2022
                key={`masthead2022-${id}-${index}`}
                masthead2022Item={
                  itemToRender.item as CompleteMasthead2022FieldsFragment['nodes'][0]
                }
                className="pb-10"
              />
            );
            break;
          case RenderedType.Tout:
            renderedItem = (
              <Tout
                key={`tout-${id}-${index}`}
                toutItem={itemToRender.item as CompleteToutFieldsFragment['nodes'][0]}
              />
            );
            break;
          case RenderedType.TwoUpGrid:
            renderedItem = (
              <TwoUpGrid
                key={`two-up-grid-${id}-${index}`}
                config={itemToRender.item as Complete2upGridFieldsFragment['nodes'][0]}
                className={classNameSeparator}
              />
            );
            break;

          case RenderedType.ThreeUpInformative:
            renderedItem = (
              <ThreeUpInformative
                key={`component-${id}-${index}`}
                threeUpInformativeItem={
                  itemToRender.item as Complete3UpInformativeFieldsFragment['nodes'][0]
                }
                className={classNameSeparator}
              />
            );
            break;
          case RenderedType.ToutText:
            renderedItem = (
              <ToutText
                key={`tout-text-${id}-${index}`}
                toutTextItem={itemToRender.item as CompleteToutTextFieldsFragment['nodes'][0]}
              />
            );
            break;
          case RenderedType.ContentAdvertise:
            renderedItem = (
              <ContentAdvertise
                key={`content-advertise-${id}-${index}`}
                contentAdItem={
                  itemToRender.item as CompleteContentAdvertiseFieldsFragment['nodes'][0]
                }
              />
            );
            break;
          case RenderedType.Quote:
            const quoteItem = itemToRender.item as CompleteQuoteFieldsFragment['nodes'][0];
            renderedItem = (
              <Quote
                key={`quote-${id}-${index}`}
                quote={quoteItem.field_quote_body}
                source={quoteItem.field_source}
                backgroundColor={quoteItem.field_background_color}
              />
            );
            break;
          case RenderedType.ToutSimple:
            renderedItem = (
              <ToutSimple
                key={`tout-simple-${id}-${index}`}
                toutSimpleItem={itemToRender.item as CompleteToutSimpleFieldsFragment['nodes'][0]}
              />
            );
            break;
          case RenderedType.SimpleMedia:
            renderedItem = (
              <SimpleMedia
                key={`simple-media-${id}-${index}`}
                simpleMediaItem={itemToRender.item as CompleteSimpleMediaFieldsFragment['nodes'][0]}
              />
            );
            break;
          case RenderedType.MediaGrid:
            renderedItem = (
              <MediaGrid
                id={`media-grid-${id}-${index}`}
                key={`media-grid-${id}-${index}`}
                mediaGridItem={itemToRender.item as CompleteMediaGridFieldsFragment['nodes'][0]}
                className={classNameSeparator}
              />
            );
            break;
          case RenderedType.Carousel:
            renderedItem = (
              <Carousel
                key={`carousel-${id}-${index}`}
                carouselItems={itemToRender.item as CompleteCarouselFieldsFragment['nodes'][0]}
                position={positionIndex}
              />
            );
            break;
          case RenderedType.ArticleTout:
            renderedItem = (
              <ArticleTout
                key={`article-tout-${id}-${index}`}
                articleToutItem={itemToRender.item as CompleteArticleToutFieldsFragment['nodes'][0]}
              />
            );
            break;
          case RenderedType.BodyContent:
            renderedItem = (
              <BodyContent
                key={`body-content-${id}-${index}`}
                bodyContentItem={itemToRender.item as CompleteBodyContentFieldsFragment['nodes'][0]}
              />
            );
            break;
          case RenderedType.DownloadableContent:
            renderedItem = (
              <DownloadableContent
                key={`downloadable-content-${id}-${index}`}
                downloadableItem={
                  itemToRender.item as CompleteDownloadableContentFieldsFragment['nodes'][0]
                }
              />
            );
            break;
          case RenderedType.QuickLinks:
            renderedItem = (
              <QuickLinks
                key={`quick-links-${id}-${index}`}
                quickLinksItem={itemToRender.item as CompleteQuickLinksFieldsFragment['nodes'][0]}
              />
            );
            break;
          case RenderedType.Tout5050:
            renderedItem = (
              <Tout5050
                key={`tout-5050-${id}-${index}`}
                tout5050Item={itemToRender.item as CompleteTout5050FieldsFragment['nodes'][0]}
                className={classNameSeparator}
              />
            );
            break;
          case RenderedType.NewsGrid:
            renderedItem = (
              <NewsGrid
                key={`news-grid-${id}-${index}`}
                config={itemToRender.item as CompleteNewsGridFieldsFragment['nodes'][0]}
                className={positionIndex !== 1 ? classNameSeparator : ''}
              />
            );
            break;
          case RenderedType.ArticlesGrid:
            const articlesTout = getComponentsByType(RenderedType.ArticleTout);
            renderedItem = (
              <ArticlesGrid
                key={`articles-grid-${id}-${index}`}
                config={itemToRender.item as CompleteArticlesGridFieldsFragment['nodes'][0]}
                exclude={articlesTout as CompleteArticleToutFieldsFragment[]}
              />
            );
            break;
          default:
            break;
        }

        const isComponentRepeated =
          renderedComponents[index - 1]?.renderedType === itemToRender.renderedType &&
          !SeparatorExceptions.includes(itemToRender.renderedType);

        return (
          <div key={index}>
            {temporalRenderComponent?.[index - 1]}
            {isComponentRepeated && Separator}
            {renderedItem}
          </div>
        );
      })}
    </div>
  );
};

export default AutoOrder;
