const ctaTitleHandler = (contentType?: string | null): string => {
  switch (contentType) {
    case 'video':
      return 'Watch the Video';
    case 'externalLink':
    case 'internalLink':
    default:
      return 'Read More';
  }
};

export default ctaTitleHandler;
