import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import TwoColumns from './twoColumns';
import { GatsbyImage } from 'gatsby-plugin-image';
import { CompleteToutSimpleFieldsFragment } from '../../graphql-types';

interface Props {
  toutSimpleItem: CompleteToutSimpleFieldsFragment['nodes'][0];
}

function getBackgroundColorClass(backgroundColor?: string | null) {
  switch (backgroundColor) {
    case 'white':
      return '';
    case 'black':
      return 'bg-black text-white';
    case 'red':
      return 'bg-red-900 text-white';
    case 'pink':
      return 'bg-pink-500 text-white';
    case 'purple':
      return 'bg-purple-500 text-white';
    case 'purple-dark':
      return 'bg-purple-900 text-white';
    case 'gray':
      return 'bg-gray-100';
    case 'green':
      return 'bg-green-600';
    case 'yellow':
      return 'bg-yellow-600';
    case 'yellow-light':
      return 'bg-yellow-100';
    default:
      return 'bg-transparent';
  }
}

const ToutSimple: React.FC<Props> = ({ toutSimpleItem }) => {
  const twoColumnsTitle = toutSimpleItem.field_custom_title?.processed
    ? ReactHtmlParser(toutSimpleItem.field_custom_title?.processed)
    : toutSimpleItem.title;
  const twoColumnsBody = ReactHtmlParser(toutSimpleItem.body?.processed ?? '');
  const toutSimpleType = toutSimpleItem.field_tout_simple_type;
  const image =
    toutSimpleItem.relationships?.field_image?.relationships?.field_media_image?.localFile
      ?.childImageSharp?.gatsbyImageData;
  const imageURL =
    toutSimpleItem.relationships?.field_image?.relationships?.field_media_image?.localFile
      ?.publicURL;

  const content = (
    <div className="laptop-standard:w-1/2 laptop-standard:px-20 laptop-standard:pl-24">
      <div className="space-y-6 laptop-standard:space-y-13">
        <div>
          <h3>{twoColumnsTitle}</h3>
        </div>
        <div className="space-y-4">
          <div className="tout">{twoColumnsBody}</div>
        </div>
        <div className="self-center">
          <img className="h-0 tablet:pt-13" src={''} alt={''} />
        </div>
      </div>
    </div>
  );

  const imgAlt = toutSimpleItem.relationships?.field_image?.field_media_image?.alt ?? '';

  let media = <></>;

  if (image || imageURL) {
    if (image) {
      media = <GatsbyImage image={image} className="h-full laptop-standard:w-1/2" alt={imgAlt} />;
    } else {
      media = <img className="object-fit-cover" src={imageURL ?? ''} alt={imgAlt} />;
    }
  }

  const backgroundColor = getBackgroundColorClass(toutSimpleItem?.field_background_color);

  if (toutSimpleType === 'flip') {
    return (
      <TwoColumns
        backgroundColor={backgroundColor}
        styles="pt-25 mobile:text-xs laptop-standard:text-2lg laptop-standard:pt-40 "
      >
        {content}
        {media}
      </TwoColumns>
    );
  }
  return (
    <TwoColumns
      backgroundColor={backgroundColor}
      styles="pt-25 mobile:text-xs laptop-standard:text-2lg laptop-standard:pt-40 text-2lg"
    >
      {media}
      {content}
    </TwoColumns>
  );
};

export default ToutSimple;
