import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import ZoomableImage from '../ZoomableImage';
import { CompleteParagraphImageFieldsFragment } from '../../../graphql-types';
import ColumnsContext from './columnsContext';

interface Props {
  index: number;
  imageParagraph: CompleteParagraphImageFieldsFragment;
}

const Image: React.FC<Props> = ({ index, imageParagraph }) => {
  const imageField = imageParagraph.relationships?.field_media;
  const imageMobileField = imageParagraph.relationships?.field_media_mobile;
  const imageStyle = imageParagraph.field_image_style;
  let imageWidth = 'padding-lateral';
  if (imageStyle === 'full') {
    imageWidth = '';
  }

  const { usedInColumns } = React.useContext(ColumnsContext);

  return (
    <div className={`${imageWidth} laptop-standard-max:px-0`}>
      {imageField?.relationships?.field_media_image?.localFile?.publicURL &&
      imageParagraph.field_zoomable ? (
        <ZoomableImage
          src={imageField.relationships.field_media_image.localFile?.publicURL}
          srcMobile={imageMobileField?.relationships?.field_media_image?.localFile?.publicURL}
          alt={imageField.field_media_image?.alt}
        />
      ) : (
        <>
          <GatsbyImage
            className={`${usedInColumns ? 'max-h-60vh' : ''} ${
              imageMobileField ? 'hidden tablet:block' : ''
            }`}
            image={
              imageField?.relationships?.field_media_image?.localFile?.childImageSharp
                ?.gatsbyImageData
            }
            alt={imageField?.field_media_image?.alt ?? ''}
            objectFit={usedInColumns ? 'contain' : 'cover'}
          />
          {imageMobileField && (
            <GatsbyImage
              className={`${usedInColumns ? 'max-h-60vh' : ''} tablet:hidden`}
              image={
                imageMobileField?.relationships?.field_media_image?.localFile?.childImageSharp
                  ?.gatsbyImageData
              }
              alt={imageField?.field_media_image?.alt ?? ''}
              objectFit={usedInColumns ? 'contain' : 'cover'}
            />
          )}
        </>
      )}
    </div>
  );
};

export default Image;
