import React from 'react';
import { useLocation } from '@reach/router';
import { CompleteQuickLinksFieldsFragment } from '../../graphql-types';
import Button from './button';

interface Props {
  quickLinksItem: CompleteQuickLinksFieldsFragment['nodes'][0];
}

const QuickLinks: React.FC<Props> = ({ quickLinksItem }) => {
  const location = useLocation();
  const title = quickLinksItem.title;
  const links = quickLinksItem.relationships?.field_links;
  return (
    <>
      <h6 className="font-bold">{title}</h6>
      <div
        className="flex mt-15 laptop-standard-max:justify-between"
        style={{ flexFlow: 'row wrap' }}
      >
        {links?.map((link, linkIndex) => {
          return link?.field_cta_link !== location.pathname ? (
            <Button
              key={`quick-link-${linkIndex}`}
              buttonTitle={link?.field_cta_label}
              wrapperStyles="all-button-quick-links min-w-max mr-2 mb-6"
              internalLink={link?.field_cta_link}
              externalLink={link?.field_cta_link}
              contentType={link?.field_cta_type}
              videoId={link?.field_vimeo_id}
            />
          ) : null;
        })}
      </div>
    </>
  );
};

export default QuickLinks;
