import React, { Component } from 'react';
import Button from '../button';
import EmbeddedVideo from '../video/embeddedVideo';
import { Link } from 'gatsby';
import RenderVideo from '../video/renderVideo';
import AnimatedImage from '../AnimatedImage';
import { Tween, ScrollTrigger } from 'react-gsap';
import SpecialClickContext from '../../context/specialClickContext';
import {
  Complete2upFieldsFragment,
  CompleteNewsFieldsNodeFragment,
  Maybe,
} from '../../../graphql-types';
import ctaIconHandler from '../button/complements/cta-icon-handler';

interface Props {
  twoUpData: Maybe<Complete2upFieldsFragment | CompleteNewsFieldsNodeFragment>;
  buttonSection?: string;
  containerSize?: number;
  containerStyle?: any;
  animated?: boolean;
}

interface State {
  onClick: () => void;
}

const styles: Record<number, string> = {
  70: 'laptop-standard:w-1/2',
  30: 'laptop-standard:w-5/12 self-end',
  20: 'laptop-standard:w-4/12 self-end',
  80: 'laptop-standard:w-7/12',
  100: 'w-full padding-lateral',
};

class TwoUpItem extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      onClick: () => null,
    };
  }

  setOnClick = (newOnClick: () => void): void => {
    this.setState({ onClick: newOnClick });
  };

  render(): React.ReactElement {
    const containerStyle = `${styles[this.props.containerSize ?? 30]} ${this.props.containerStyle}`;

    const buttonTitle = this.props.twoUpData?.field_button_title?.trim() ?? '';

    const image = this.props.twoUpData?.relationships?.field_image;

    const video = (this.props.twoUpData as Maybe<Complete2upFieldsFragment>)?.relationships
      ?.field_video?.relationships?.field_media_video_file?.localFile?.publicURL;
    const videoMobile = (this.props.twoUpData as Maybe<Complete2upFieldsFragment>)?.relationships
      ?.field_mobile_video?.relationships?.field_media_video_file?.localFile?.publicURL;

    const icon = this.props.twoUpData?.field_button_title
      ? ctaIconHandler(this.props.twoUpData?.field_content_type)
      : null;

    const contentType = this.props.twoUpData?.field_content_type;

    const videoURL = (this.props.twoUpData as Maybe<Complete2upFieldsFragment>)?.field_video_url;

    const title = (
      <Button
        buttonTitle={buttonTitle}
        icon={icon}
        className="laptop-standard:h-27"
        styles="cta-link-format cta-link-hover-bold cta-svg-absolute"
        contentType={contentType}
        internalLink={this.props.twoUpData?.field_link}
        externalLink={this.props.twoUpData?.field_link}
        linkTitle={this.props.twoUpData?.title ?? ''}
        videoId={(this.props.twoUpData as Maybe<Complete2upFieldsFragment>)?.field_video_id}
        buttonType={'text'}
        section={this.props.buttonSection}
      />
    );

    let content;

    if (videoURL) {
      content = (
        <EmbeddedVideo
          videoURL={videoURL}
          containerStyle={`!aspect-ratio-16/9 ${this.props.containerStyle}`}
        />
      );
    } else {
      let imageComponent;
      if (video) {
        content = (
          <RenderVideo
            onClick={this.state.onClick}
            backgroundImage={image?.relationships?.field_media_image?.localFile?.publicURL}
            video={video}
            videoMobile={videoMobile}
            loop
          />
        );
      } else {
        imageComponent = (
          <AnimatedImage
            className="mobile2up cursor-pointer"
            image={image}
            animated={this.props.animated}
          />
        );
      }
      if (contentType === 'internalLink') {
        content = (
          <Link
            to={this.props.twoUpData?.field_link ?? ''}
            title={image?.field_media_image?.alt ?? (videoURL || video) ? 'play video' : ''}
          >
            {imageComponent ?? content}
          </Link>
        );
      } else if (contentType === 'externalLink') {
        content = (
          <a
            target="_blank"
            rel="noreferrer"
            href={this.props.twoUpData?.field_link ?? ''}
            title={image?.field_media_image?.alt ?? (videoURL || video) ? 'play video' : ''}
          >
            {imageComponent ?? content}
          </a>
        );
      } else if (!content) {
        content = imageComponent;
      }
    }
    return (
      <SpecialClickContext.Provider
        value={{ onClick: this.state.onClick, setOnClick: this.setOnClick }}
      >
        <div className={containerStyle}>
          <ScrollTrigger
            start="-500px center"
            end="100px center"
            toggleActions="play none none none"
          >
            <Tween
              from={{
                transform: 'translateY(-50px)',
                opacity: 0,
              }}
              duration={1}
            >
              <div onClick={this.state.onClick}>{content}</div>
            </Tween>
            <Tween
              from={{
                transform: 'translateY(20px)',
                opacity: 0,
              }}
              duration={1.5}
            >
              <div>{title}</div>
            </Tween>
          </ScrollTrigger>
        </div>
      </SpecialClickContext.Provider>
    );
  }
}

export default TwoUpItem;
