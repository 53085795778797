import React, { Component, ReactNode } from 'react';
import ReactHtmlParser from 'react-html-parser';

interface Props {
  styles?: string;
  content: string;
}

class TextIntro extends Component<Props> {
  render(): ReactNode {
    const { styles, content } = this.props;
    return (
      <h4
        className={`mobile:mr-2 laptop-standard:mx-32 mobile:pb-2
        tablet:pb-25 laptop-standard:pr-8 laptop-large:pr-19/80 laptop-large:mr-0
        ${styles ?? ''}`}
      >
        {ReactHtmlParser(content)}
      </h4>
    );
  }
}

export default TextIntro;
