import React, { useEffect, useState } from 'react';

function useOnScreen(ref: React.MutableRefObject<any | null>): boolean {
  const [isIntersecting, setIntersecting] = useState(false);

  const observer = new IntersectionObserver(([entry]) => setIntersecting(entry.isIntersecting));

  useEffect(() => {
    if (ref && ref.current && ref.current !== null) {
      observer.observe(ref.current);
      return () => {
        observer.disconnect();
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref]);

  return isIntersecting;
}

export default useOnScreen;
