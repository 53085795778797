import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import AnimatedImage from '../AnimatedImage';
import Button from '../button';
import { ThreeUpInformativeProps } from './props';

const ThreeUpInformative: React.FC<ThreeUpInformativeProps> = ({
  threeUpInformativeItem,
  className,
}) => {
  const image = threeUpInformativeItem.relationships?.field_media;
  const title = threeUpInformativeItem.title;
  const subtitle = threeUpInformativeItem.body?.processed;
  const threeUp = threeUpInformativeItem.relationships?.field_3_up_items;
  const CTAlabel = threeUpInformativeItem.field_button_title;
  const CTAlink = threeUpInformativeItem.field_link;

  return (
    <div
      className={`flex flex-col padding-lateral max-width items-center text-center ${className}`}
    >
      <hr className="w-full mb-15 laptop-standard:mb-25" />
      <div className="w-full laptop-standard:w-1/2 mb-15">
        <AnimatedImage image={image} />
      </div>
      <h3 className="mb-5">{title}</h3>
      {ReactHtmlParser(subtitle ?? '')}
      <div className="grid grid-cols-1 laptop-standard:grid-cols-3 gap-x-16 gap-y-10 mt-10 laptop-standard:mt-16 mb-20">
        {threeUp?.map((item, index) => (
          <div className="flex flex-col text-left" key={`threeUp-item-${index}`}>
            <h6 className="font-bold mb-2 laptop-standard:mb-4">{item?.field_title}</h6>
            {ReactHtmlParser(item?.field_copy?.processed ?? '')}
          </div>
        ))}
      </div>
      <Button
        buttonTitle={CTAlabel}
        styles="!px-8 !font-bold"
        contentType={CTAlink?.startsWith('/') ? 'internalLink' : 'externalLink'}
        externalLink={CTAlink ?? '#'}
        internalLink={CTAlink ?? '#'}
      />
    </div>
  );
};

export default ThreeUpInformative;
