import React, { Component, JSXElementConstructor, ReactElement } from 'react';
import { Link } from 'gatsby';
import Container from '../container';
import ctaTitleHandler from '../button/complements/cta-title-handler';
import { Tween, SplitWords, ScrollTrigger } from 'react-gsap';
import { Maybe } from '../../../graphql-types';
import VideoModal from '../videoModal';

interface Props {
  className?: string;
  titleClassName?: string;
  title?: Maybe<string> | ReactElement<any, string | JSXElementConstructor<any>>[];
  body?: string | ReactElement<any, string | JSXElementConstructor<any>>[];
  fieldLink?: Maybe<string>;
  videoID?: Maybe<string>;
  buttonTitle?: Maybe<string>;
  buttonType?: 'text';
  contentType?: Maybe<string>;
}

class RenderText extends Component<Props> {
  render(): React.ReactNode {
    let body: React.ReactNode | string | undefined = this.props.body;
    if (this.props.buttonType === 'text') {
      const isBodyString = typeof this.props.body === 'string';
      if (this.props.contentType === 'externalLink') {
        const ctaLink = (
          <p>
            {isBodyString ? `${this.props.body} ` : this.props.body}
            {this.props.fieldLink && (
              <a
                target="_blank"
                rel="noreferrer"
                className="cta-link font-bold"
                href={this.props.fieldLink ?? ''}
              >
                {' '}
                {this.props.buttonTitle ?? ctaTitleHandler(this.props.contentType)}
              </a>
            )}
          </p>
        );
        body = ctaLink;
      } else if (this.props.contentType === 'internalLink') {
        const ctaLink = (
          <p>
            {isBodyString ? `${this.props.body} ` : this.props.body}
            {this.props.fieldLink && (
              <Link className="cta-link font-bold" to={this.props.fieldLink ?? ''}>
                {' '}
                {this.props.buttonTitle ?? ctaTitleHandler(this.props.contentType)}
              </Link>
            )}
          </p>
        );
        body = ctaLink;
      } else if (this.props.contentType === 'video') {
        const ctaLink = (
          <p>
            <VideoModal
              buttonType="text"
              styles="font-bold"
              description={this.props.body}
              videoId={this.props.videoID}
              linkTitle={this.props.buttonTitle ?? ctaTitleHandler(this.props.contentType)}
            />
          </p>
        );

        body = ctaLink;
      }
    }
    return (
      <div className={this.props.className ?? ''}>
        <Container>
          <div className={'container-two-columns padding-lateral'}>
            <div className={`title-container-two-columns ${this.props.titleClassName ?? ''}`}>
              {typeof this.props.title === 'string' ? (
                <ScrollTrigger
                  start="-400px center"
                  end="1px center"
                  toggleActions="play none none none"
                >
                  <Tween
                    from={{ y: '20px', opacity: 0 }}
                    stagger={0.2}
                    duration={3}
                    ease="elastic.out(0.1, 0.1)"
                  >
                    <SplitWords wrapper={<div style={{ display: 'inline-block' }} />}>
                      {this.props.title}
                    </SplitWords>
                  </Tween>
                </ScrollTrigger>
              ) : (
                <div style={{ display: 'inline-block' }}>{this.props.title}</div>
              )}
            </div>
            <div className="desktop-large:w-32"></div>
            <ScrollTrigger
              start="-400px center"
              end="1px center"
              toggleActions="play none none none"
            >
              <Tween from={{ y: '40px', opacity: 0 }} stagger={0.2} duration={4} ease="slow">
                <div className="body-container-two-columns">{body}</div>
              </Tween>
            </ScrollTrigger>
          </div>
        </Container>
      </div>
    );
  }
}

export default RenderText;
