import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import ContentImage from '../../contentImage';
import ContentLarge from '../../contentLarge';
import { ToutTextProps } from '../props';

const ToutTextLink: React.FC<ToutTextProps> = ({ toutTextItem }) => {
  return (
    <div className="bg-black margin-lateral">
      <ContentImage backgroundColorLazy="bg-purple-700" textColor="text-yellow-300">
        <div className="w-full text-center pb-10 laptop-standard:w-1/2 laptop-standard:pt-15 laptop-standard:pb-32">
          <p className="text-white title-container-banner">
            {toutTextItem.field_custom_title?.processed
              ? ReactHtmlParser(toutTextItem.field_custom_title.processed)
              : toutTextItem.title}
          </p>
        </div>
        <ContentLarge
          title={ReactHtmlParser(toutTextItem.body?.processed ?? '')}
          buttonTitle={toutTextItem.field_button_title ?? ''}
          styles={'text-orange-600 text-white laptop-standard:h-12 !px-6 !py-8 !font-bold'}
          type="landing"
          contentType={'internalLink'}
          internalLink={toutTextItem.field_link ?? ''}
          titleClassContainer="format-for-content-title-large text-white"
          containerStyle="py-2 laptop-standard:py-20 laptop-standard:pl-24 laptop-large:py-10 desktop-large:px-40"
        />
      </ContentImage>
    </div>
  );
};

export default ToutTextLink;
