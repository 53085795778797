import React, { Component, ReactNode } from 'react';
import Eyebrows from './complements/eyebrows';
import { BgImage } from 'gbimage-bridge';
import Base from './article/base';
import { IGatsbyImageData } from 'gatsby-plugin-image';

interface Props {
  textColor?: string;
  backgroundImageMobile?: IGatsbyImageData;
  backgroundImage?: IGatsbyImageData;
  backgroundColorLazy?: string;
  eyebrow1?: string;
  eyebrow2?: string;
  altImg?: string;
  styles?: string;
}

class ContentImage extends Component<Props> {
  render(): ReactNode {
    const defaultTextColor = this.props.textColor ?? 'text-white';
    const sources = [
      this.props.backgroundImageMobile,
      {
        ...this.props.backgroundImage,
        media: '(min-width: 1024px)',
      },
    ];

    const eyebrows =
      this.props.eyebrow1 || this.props.eyebrow2 ? (
        <div className="container max-width">
          <Eyebrows
            eyebrow1={this.props.eyebrow1 ?? ''}
            eyebrow2={this.props.eyebrow2 ?? ''}
            textColor={defaultTextColor}
          />
        </div>
      ) : (
        <></>
      );

    const WrapperComponent =
      this.props.backgroundImage || this.props.backgroundImageMobile ? BgImage : Base;

    const additionalProps = {
      className: 'mobile:pt-7 laptop-standard:pt-14 pb-13 laptop-standard:pb-14 padding-lateral',
    };

    return (
      <WrapperComponent
        image={sources as any}
        alt={this.props.altImg ?? ''}
        backgroundColor={this.props.backgroundColorLazy}
        {...additionalProps}
      >
        {eyebrows}
        <div
          className={`container max-width content-image-children space-y-6
          laptop-standard:space-y-13 mobile:pt-6 laptop-standard:pt-0 ${this.props.styles ?? ''}`}
        >
          {this.props.children}
        </div>
      </WrapperComponent>
    );
  }
}

export default ContentImage;
