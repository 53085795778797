import React, { Component } from 'react';
import Button from '../button';
import { Link } from 'gatsby';
import { Maybe, MediaImageBlurredUrl90Fragment } from '../../../graphql-types';
import AnimatedImage from '../AnimatedImage';
import ctaIconHandler from '../button/complements/cta-icon-handler';

interface Props {
  key: string;
  title?: Maybe<string>;
  buttonTitle?: Maybe<string>;
  image?: Maybe<MediaImageBlurredUrl90Fragment>;
  contentType?: Maybe<string>;
  link: string;
  videoId?: Maybe<string>;
  date?: Maybe<string>;
  icon?: Maybe<string>;
  styles: string;
  titleClassName?: string;
  newsContainerClassName?: Maybe<string>;
  hideButton: boolean;
  imgAlt?: Maybe<string>;
}

const renderImageWithLink = (data: Props) => {
  const imageComponent = (
    <AnimatedImage image={data.image} className="laptop-standard:h-48" animated />
  );
  return data.contentType === 'externalLink' ? (
    <a href={data.link ?? ''} target="_blank" rel="noreferrer">
      {imageComponent}
    </a>
  ) : (
    <Link to={data.link}>{imageComponent}</Link>
  );
};

class NewsItem extends Component<Props> {
  render(): React.ReactNode {
    const image = renderImageWithLink(this.props);

    const icon = this.props.buttonTitle ? ctaIconHandler(this.props.contentType) : null;

    const title = (
      <Button
        buttonTitle={this.props.buttonTitle}
        wrapperStyles={this.props.titleClassName}
        styles="cta-link-format cta-link-hover-bold cta-svg-absolute"
        icon={icon}
        contentType={this.props.contentType}
        internalLink={this.props.link}
        externalLink={this.props.link}
        linkTitle={this.props.title ?? ''}
        buttonType={'text'}
      />
    );

    return (
      <div className={this.props.newsContainerClassName ?? 'space-y-5 laptop-standard:space-y-13'}>
        {image}
        {title}
      </div>
    );
  }
}

export default NewsItem;
