import React from 'react';
import TwoColumns from '../twoColumns';
import {
  Complete2upFieldsFragment,
  CompleteNewsFieldsNodeFragment,
  Maybe,
} from '../../../graphql-types';
import TwoUpItem from './twoUpItem';

interface Props {
  twoUpItems: Maybe<Complete2upFieldsFragment | CompleteNewsFieldsNodeFragment>[];
  arrangement: string;
  className?: string;
  childClass?: string[];
}

const DuetContainer: React.FC<Props> = ({ twoUpItems, arrangement, className, childClass }) => {
  const firstNew = twoUpItems[0];
  const secondNew = twoUpItems[1];
  const arrangementValues = arrangement.split('/').map((value) => parseInt(value, 10));

  return (
    <TwoColumns styles={className}>
      <TwoUpItem
        twoUpData={firstNew}
        containerSize={arrangementValues[0]}
        animated
        containerStyle={childClass?.[0]}
      />
      <TwoUpItem
        twoUpData={secondNew}
        containerSize={arrangementValues[1]}
        animated
        containerStyle={childClass?.[1]}
      />
    </TwoColumns>
  );
};

export default DuetContainer;
