import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import MediaGridItem from '../paragraphs/MediaGridItem';
import { MediaGridProps } from './props';

const MediaGrid: React.FC<MediaGridProps> = ({ id, mediaGridItem, className }) => {
  const title = mediaGridItem.title;
  const copy = mediaGridItem.body?.processed;
  const mediaItems = mediaGridItem.relationships?.field_items;

  return (
    <div className={`flex flex-col padding-lateral max-width items-center ${className}`}>
      <hr className="w-full mb-15 laptop-standard:mb-25" />
      <h3 className="mb-5 text-center">{title}</h3>
      <div className="laptop-standard:w-4/5 text-center">{ReactHtmlParser(copy ?? '')}</div>
      <div className="grid grid-cols-1 laptop-standard:grid-cols-3 pt-16 gap-x-5 gap-y-12 laptop-standard:gap-y-10">
        {mediaItems?.map((item, itemIndex) => (
          <MediaGridItem
            key={`${id}-${itemIndex}`}
            index={itemIndex}
            mediaItemParagraph={item}
            titleClass="font-bold"
            buttonType="text"
          />
        ))}
      </div>
      <hr className="w-full mt-15 laptop-standard:mt-25" />
    </div>
  );
};

export default MediaGrid;
