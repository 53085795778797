import React from 'react';
import { CompleteParagraphSeparatorFieldsFragment } from '../../../graphql-types';
import { Spacing, SpacingMobile } from './Spacer';

interface Props {
  index: number;
  separatorParagraph: CompleteParagraphSeparatorFieldsFragment;
}

const Separator: React.FC<Props> = ({ index, separatorParagraph }) => {
  const topSpacing = (separatorParagraph.relationships?.field_top_spacing?.field_spacing ??
    'none') as keyof typeof Spacing;
  const bottomSpacing = (separatorParagraph.relationships?.field_bottom_spacing?.field_spacing ??
    'none') as keyof typeof Spacing;

  return (
    <hr
      className={`mt-${SpacingMobile[topSpacing]} mb-${SpacingMobile[bottomSpacing]} laptop-standard:mt-${Spacing[topSpacing]} laptop-standard:mb-${Spacing[bottomSpacing]} ml-0 mr-0`}
    />
  );
};

export default Separator;
