/* eslint-disable @typescript-eslint/no-var-requires */
import React, { useRef, useState, useEffect } from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import Slider, { Settings } from 'react-slick';
import Button from '../button';
import ctaTitleHandler from '../button/complements/cta-title-handler';
import ctaIconHandler from '../button/complements/cta-icon-handler';
import RenderVideo from '../video/renderVideo';
import useOnScreen from '../../utils/useOnScreen';
import './_carousel.css';

// Icons
import EmptyCircle from '../../images/icons/empty-circle.svg';
import { CompleteCarouselFieldsFragment } from '../../../graphql-types';

const backgroundColor: Record<string, string> = {
  black: 'bg-black',
  pink: 'bg-pink-300',
  purple: 'bg-purple-400',
};

const borderColor: Record<string, string> = {
  black: 'border-black',
  pink: 'border-pink-300',
  purple: 'border-purple-400',
};

const textColor: Record<string, string> = {
  black: 'text-black',
  white: 'text-white',
};

interface Props {
  carouselItems: CompleteCarouselFieldsFragment['nodes'][0];
  position: number;
}

const convertItemsToSlides = (items: Props['carouselItems']): React.ReactNode[] | undefined => {
  return items.relationships?.field_carousel_items?.map((slide) => {
    let background;
    const cta = (
      <div className="laptop-standard:w-full laptop-standard:flex laptop-standard:justify-end">
        <Button
          contentType={slide?.field_content_type}
          externalLink={slide?.field_link}
          internalLink={slide?.field_link}
          buttonTitle={slide?.field_button_title ?? ctaTitleHandler(slide?.field_content_type)}
          icon={ctaIconHandler(slide?.field_content_type)}
          buttonType="text"
          styles="cta-link-hero-carousel"
          videoId={slide?.field_video_id}
          section={'carousel'}
        />
      </div>
    );

    const titleDesk = (
      <Button
        contentType={slide?.field_content_type}
        externalLink={slide?.field_link}
        internalLink={slide?.field_link}
        linkTitle={slide?.title ?? ''}
        buttonType="text"
        styles="hidden laptop-standard:block title-hero-carousel"
        videoId={slide?.field_video_id}
        section={'carousel'}
      />
    );
    const titleMobile = (
      <Button
        contentType={slide?.field_content_type}
        linkTitle={slide?.title ?? ''}
        externalLink={slide?.field_link}
        internalLink={slide?.field_link}
        buttonType="text"
        styles="laptop-standard:hidden title-hero-carousel"
        videoId={slide?.field_video_id}
        section={'carousel'}
        buttonTitle={slide?.field_button_title ?? ctaTitleHandler(slide?.field_content_type)}
        icon={ctaIconHandler(slide?.field_content_type)}
      />
    );
    if (
      slide?.relationships?.field_video?.relationships?.field_media_video_file?.localFile?.publicURL
    ) {
      background = (
        <div className="hero-carousel-videos">
          <RenderVideo
            loop
            video={
              slide.relationships.field_video.relationships.field_media_video_file.localFile
                .publicURL
            }
            videoMobile={
              slide.relationships.field_mobile_video?.relationships?.field_media_video_file
                ?.localFile?.publicURL
            }
            videoClassName="video-container-style"
          />
        </div>
      );
    } else {
      background = (
        <GatsbyImage
          className="hero-carousel-images"
          image={
            slide?.relationships?.field_image?.relationships?.field_media_image?.localFile
              ?.childImageSharp?.gatsbyImageData
          }
          alt={slide?.relationships?.field_image?.field_media_image?.alt ?? ''}
        />
      );
    }
    return (
      <div>
        <div className="flex-slider">
          <div className="tablet:w-full tablet:h-8 laptop-standard:h-14 flex-initial"></div>
          <div
            className={`cta-container-hero-carousel ${
              slide?.field_carousel_background_color
                ? backgroundColor[slide?.field_carousel_background_color]
                : backgroundColor[Object.keys(backgroundColor)[0]]
            }
            ${
              slide?.field_carousel_text_color
                ? textColor[slide.field_carousel_text_color]
                : textColor[Object.keys(textColor)[0]]
            } flex-auto`}
          >
            <div className="laptop-standard:w-9/12 flex-1 max-character">
              {titleDesk}
              {titleMobile}
            </div>
            <div className="cta-button">{cta}</div>
            <div className="triangle-container">
              <div
                className={`triangle ${
                  slide?.field_carousel_background_color
                    ? borderColor[slide?.field_carousel_background_color]
                    : borderColor[Object.keys(borderColor)[0]]
                }`}
              ></div>
            </div>
          </div>
        </div>
        {background}
      </div>
    );
  });
};

const CarouselHero: React.FC<Props> = ({ carouselItems, position }) => {
  const ref = useRef<HTMLDivElement>(null);
  const sliderRef = useRef<Slider>(null);
  const isVisible = useOnScreen(ref);
  const [slides, setSlides] = useState<React.ReactNode[]>();

  useEffect(() => {
    // we have to manually play and pause the slick because of current bug
    // https://github.com/akiran/react-slick/issues/2065
    if (isVisible) {
      sliderRef.current?.slickPlay?.();
    } else {
      sliderRef.current?.slickPause?.();
    }
  }, [isVisible]);

  useEffect(() => {
    setSlides(convertItemsToSlides(carouselItems));
  }, [carouselItems]);

  const settings: Settings = {
    arrows: false,
    dots: true,
    dotsClass: 'slick-dots slick-thumb',
    infinite: true,
    speed: 2000,
    autoplaySpeed: 5000,
    slidesToShow: 1,
    slidesToScroll: 1,
    pauseOnHover: true,
    pauseOnDotsHover: true,
    customPaging: function () {
      return <EmptyCircle className="svgHero" />;
    },
  };

  return (
    <div
      ref={ref}
      className={`hero-carousel-container container max-width ${position === 0 ? '' : 'mt-20'}`}
    >
      <Slider ref={sliderRef} {...settings} autoplay={isVisible}>
        {slides?.map((slide, index) => {
          return <div key={`slide-carousel-${index}`}>{slide}</div>;
        })}
      </Slider>
    </div>
  );
};

export default CarouselHero;
