import React from 'react';
import AnimatedImage from '../AnimatedImage';
import Button from '../button';
import { Masthead2022Props } from './props';
import getIconForContentType from '../button/complements/cta-icon-handler';
import ReactHtmlParser from 'react-html-parser';
import VideoModal from '../videoModal';
import Video from '../paragraphs/Video';

const Masthead2022: React.FC<Masthead2022Props> = ({ masthead2022Item, className }) => {
  const backgroundVideoDesktop =
    masthead2022Item.relationships?.field_video?.relationships?.field_media_video_file?.localFile
      ?.publicURL;
  const backgroundVideoMobile =
    masthead2022Item.relationships?.field_mobile_video?.relationships?.field_media_video_file
      ?.localFile?.publicURL;
  const backgroundImageDesktop = masthead2022Item.relationships?.field_image;
  const backgroundImageMobile = masthead2022Item.relationships?.field_mobile_image;

  const mediaField = backgroundVideoDesktop ? (
    <Video
      index={10}
      videoParagraph={{
        relationships: {
          // eslint-disable-next-line camelcase
          field_video: masthead2022Item.relationships?.field_video,
          // eslint-disable-next-line camelcase
          field_video_mobile: masthead2022Item.relationships?.field_mobile_video,
        },
      }}
    />
  ) : backgroundImageDesktop ? (
    <AnimatedImage image={backgroundImageDesktop} imageMobile={backgroundImageMobile} />
  ) : (
    <hr />
  );

  const titleComponent =
    masthead2022Item.field_headline_size === 'large' ? (
      <div className="headline2022 mt-10 laptop-standard:mt-16 pb-4 laptop-standard:pb-9">
        {ReactHtmlParser(masthead2022Item.field_headline?.processed ?? '')}
      </div>
    ) : (
      <div className="mt-5">
        {ReactHtmlParser(masthead2022Item.field_headline?.processed ?? '')}
      </div>
    );

  return (
    <div
      className={`masthead2022 flex flex-col justify-center max-width padding-lateral ${className}`}
    >
      {mediaField}
      <div className={`content_${masthead2022Item.field_headline_alignment}`}>
        {titleComponent}
        {masthead2022Item.field_button_title && masthead2022Item.field_button_type !== 'video' && (
          <Button
            styles="text-orange-600 !font-bold cursor-pointer"
            buttonType="text"
            icon={getIconForContentType(masthead2022Item.field_button_type, true)}
            iconAlt={masthead2022Item.field_button_title}
            buttonTitle={masthead2022Item.field_button_title}
            contentType={masthead2022Item.field_button_type}
            internalLink={masthead2022Item.field_link}
            externalLink={masthead2022Item.field_link}
          />
        )}
        {masthead2022Item.field_button_title && masthead2022Item.field_button_type === 'video' && (
          <VideoModal
            buttonTitle={masthead2022Item.field_button_title}
            buttonType="text"
            icon={getIconForContentType(masthead2022Item.field_button_type, true)}
            iconAlt="play video"
            channel={masthead2022Item.field_video_id ? 'vimeo' : 'custom'}
            videoURL={
              masthead2022Item.field_video_id
                ? null
                : backgroundVideoDesktop ?? backgroundVideoMobile
            }
            videoId={masthead2022Item.field_video_id}
          />
        )}
        {masthead2022Item.field_copy?.processed && (
          <>
            {<hr />}
            {ReactHtmlParser(masthead2022Item.field_copy.processed)}
          </>
        )}
      </div>
    </div>
  );
};

export default Masthead2022;
