import React, { useState } from 'react';
import { Complete2upGridFieldsFragment } from '../../../graphql-types';
import TwoUpContainer from './twoUpContainer';
import Button from '../button';
import TwoUpItem from './twoUpItem';

interface Props {
  config: Complete2upGridFieldsFragment['nodes'][0];
  className?: string;
}

const TwoUpGrid: React.FC<Props> = ({ config, className }) => {
  const {
    'field_show_load_more_': showLoadMore,
    'field_initial_items_displayed': twoUpsDisplayed,
  } = config;

  const [twoUpsToShow, setTwoUpsToShow] = useState(twoUpsDisplayed);

  const twoUpsData = twoUpsToShow
    ? config.relationships?.field_2ups?.slice(0, twoUpsToShow)
    : config.relationships?.field_2ups;

  return (
    <div className={`container max-width ${className}`}>
      {twoUpsData?.map((twoUp, index) => {
        if (twoUp?.__typename === 'node__duet') {
          return twoUp?.relationships?.field_2up_items?.[1] && twoUp.field_arrangement ? (
            <TwoUpContainer
              key={index}
              twoUpItems={twoUp?.relationships?.field_2up_items}
              arrangement={twoUp.field_arrangement}
              className={twoUpsData.length - 1 !== index ? 'pb-15' : ''}
              childClass={['pb-15 laptop-standard:pb-0']}
            />
          ) : (
            <> </>
          );
        } else if (twoUp?.__typename === 'node__2up') {
          return (
            <TwoUpItem
              key={index}
              twoUpData={twoUp}
              buttonSection="2up-single"
              containerSize={100}
              containerStyle={`w-full ${twoUpsData.length - 1 !== index ? 'pb-15' : ''}`}
            />
          );
        }
        return <> </>;
      }) ?? <> </>}
      {showLoadMore &&
        twoUpsDisplayed &&
        twoUpsToShow &&
        config.relationships?.field_2ups &&
        twoUpsToShow < config.relationships?.field_2ups?.length && (
          <Button
            contentType="loadItems"
            handleClick={() =>
              setTwoUpsToShow((value) => (value ? value + twoUpsDisplayed : twoUpsDisplayed))
            }
            linkTitle="Load More"
            styles="container mx-auto py-20 flex justify-center"
          />
        )}
    </div>
  );
};

export default TwoUpGrid;
