import React from 'react';
import { CompleteBodyBuilderItemsFragment } from '../../graphql-types';
import Button from '../components/paragraphs/Button';
import Columns from '../components/paragraphs/Columns';
import ColumnsContext from '../components/paragraphs/columnsContext';
import Component from '../components/paragraphs/Component';
import Image from '../components/paragraphs/Image';
import Video from '../components/paragraphs/Video';
import MediaGridItem from '../components/paragraphs/MediaGridItem';
import Separator from '../components/paragraphs/Separator';
import Spacer from '../components/paragraphs/Spacer';
import TitleCopy from '../components/paragraphs/TitleCopy';

interface Props {
  id: string;
  items: CompleteBodyBuilderItemsFragment['nodes'][0];
  className?: string;
  position: number;
}

const PageBuilder: React.FC<Props> = ({ id, items, position, className }) => {
  const { usedInColumns } = React.useContext(ColumnsContext);
  return (
    <div className={`${usedInColumns ? '' : 'padding-lateral max-width'} ${className ?? ''}`}>
      {items.relationships?.field_body_items?.map((item, index) => {
        switch (item?.__typename) {
          case 'paragraph__image': {
            return <Image key={`pb-image-${id}-${index}`} index={index} imageParagraph={item} />;
          }
          case 'paragraph__video': {
            return <Video key={`pb-video-${id}-${index}`} index={index} videoParagraph={item} />;
          }
          case 'paragraph__separator': {
            return (
              <Separator
                key={`pb-separator-${id}-${index}`}
                index={index}
                separatorParagraph={item}
              />
            );
          }
          case 'paragraph__title_copy': {
            return (
              <TitleCopy
                key={`pb-title-copy-${id}-${index}`}
                index={index}
                position={position}
                titleCopyParagraph={item}
              />
            );
          }
          case 'paragraph__spacer': {
            return <Spacer key={`pb-spacer-${id}-${index}`} index={index} spacerParagraph={item} />;
          }
          case 'paragraph__component': {
            return (
              <Component
                id={`${id}-${index}`}
                key={`pb-component-${id}-${index}`}
                index={index}
                componentParagraph={item}
              />
            );
          }
          case 'paragraph__media_grid_item': {
            return (
              <MediaGridItem
                key={`pb-media-grid-item-${id}-${index}`}
                index={index}
                mediaItemParagraph={item}
                buttonStyles="!pt-7"
              />
            );
          }
          case 'paragraph__columns': {
            return (
              <Columns
                id={`${id}-${index}`}
                key={`pb-columns-${id}-${index}`}
                position={position}
                columnsParagraph={item}
              />
            );
          }
          case 'paragraph__link': {
            return <Button key={`pb-image-${id}-${index}`} index={index} linkParagraph={item} />;
          }
          default:
            return null;
        }
      })}
    </div>
  );
};

export default PageBuilder;
