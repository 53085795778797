import React, { Component, ReactNode } from 'react';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';

interface Props {
  title: React.ReactElement<any, string | React.JSXElementConstructor<any>>[] | string;
  body: React.ReactElement<any, string | React.JSXElementConstructor<any>>[] | string;
  images: IGatsbyImageData;
  imgAlt?: string;
}

class ThreeColumns extends Component<Props> {
  render(): ReactNode {
    const { images } = this.props;
    return (
      <div
        className="pt-13 laptop-standard:pt-18 padding-lateral
    laptop-standard:flex laptop-standard:justify-between
    laptop-standard:items-center"
      >
        <h5 className="self-start font-bold laptop-standard:w-3/12 laptop-standard:leading-14">
          {this.props.title}
        </h5>
        <div
          className="body1 self-start mobile:pt-3 mobile:pb-12 laptop-standard:pt-0
        laptop-standard:pb-13 laptop-standard:w-4/12"
        >
          {this.props.body}
        </div>
        <GatsbyImage
          image={images}
          className="w-5/6 laptop-standard:w-3/24 max-w-xs"
          alt={this.props.imgAlt ?? ''}
          style={{ width: images?.width * 200, height: images?.height * 200 }}
        />
      </div>
    );
  }
}

export default ThreeColumns;
