import React, { useRef } from 'react';
import { Magnifier, MOUSE_ACTIVATION, TOUCH_ACTIVATION } from 'react-image-magnifiers';
import { Maybe } from '../../graphql-types';
import ZoomIn from '../images/icons/zoom-in.svg';
import ZoomOut from '../images/icons/zoom-out.svg';
import ColumnsContext from './paragraphs/columnsContext';

interface Props {
  src: string;
  srcMobile?: Maybe<string>;
  alt?: string | null;
  className?: string;
}

const ZoomableImage: React.FC<Props> = ({ src, alt, srcMobile }) => {
  const { usedInColumns } = React.useContext(ColumnsContext);
  const [isZoomed, setIsZoomed] = React.useState(false);

  const desktopMagnifier = useRef<any>();
  const mobileMagnifier = useRef<any>();

  const magnifierWrapper = useRef<any>();

  return (
    <div className={usedInColumns ? '' : ' tablet:max-w-3/5 mx-auto'}>
      <div ref={magnifierWrapper}>
        <Magnifier
          imageSrc={src}
          mouseActivation={MOUSE_ACTIVATION.CLICK}
          touchActivation={TOUCH_ACTIVATION.TAP}
          dragToMove={false}
          imageAlt={alt ?? ''}
          className={`zoomable-image ${srcMobile ? 'hidden tablet:block' : ''}`}
          onZoomStart={() => setIsZoomed(true)}
          onZoomEnd={() => setIsZoomed(false)}
          interactionSettings={{ ref: desktopMagnifier } as any}
        />
        {srcMobile && (
          <Magnifier
            imageSrc={srcMobile}
            mouseActivation={MOUSE_ACTIVATION.CLICK}
            touchActivation={TOUCH_ACTIVATION.TAP}
            dragToMove={false}
            imageAlt={alt ?? ''}
            className="zoomable-image tablet:hidden"
            onZoomStart={() => setIsZoomed(true)}
            onZoomEnd={() => setIsZoomed(false)}
            interactionSettings={{ ref: mobileMagnifier } as any}
          />
        )}
      </div>
      <div
        className="flex justify-end mt-4"
        onClick={() => {
          const windowWidth = window.innerWidth;
          const windowHeight = window.innerHeight;
          const wrapperWidth = magnifierWrapper?.current?.children?.[0]?.clientWidth;
          const wrapperHeight = magnifierWrapper?.current?.children?.[0]?.clientHeight;

          if (windowWidth > 640) {
            if (desktopMagnifier?.current?.state?.active) {
              setIsZoomed(false);
              desktopMagnifier?.current?.setState?.({
                'active': false,
              });
            } else {
              setIsZoomed(true);
              desktopMagnifier?.current?.setState?.({
                'active': true,
                'activePosition': {
                  'x': wrapperWidth,
                  'y': wrapperHeight,
                },
                'prevActivePosition': {
                  'x': wrapperWidth,
                  'y': wrapperHeight,
                },
                'passivePosition': {
                  'x': 0,
                  'y': 0,
                },
                'elementOffset': {
                  'x': 0,
                  'y': 0,
                },
                'itemPosition': {
                  'x': 0,
                  'y': 0,
                },
                'itemDimensions': {
                  'width': windowWidth,
                  'height': windowHeight,
                },
                'elementDimensions': {
                  'width': wrapperWidth,
                  'height': wrapperHeight,
                },
              });
            }
          } else {
            if (mobileMagnifier?.current?.state?.active) {
              setIsZoomed(false);
              mobileMagnifier?.current?.setState?.({
                'active': false,
              });
            } else {
              setIsZoomed(true);
              mobileMagnifier?.current?.setState?.({
                'active': true,
                'activePosition': {
                  'x': wrapperWidth,
                  'y': wrapperHeight,
                },
                'prevActivePosition': {
                  'x': wrapperWidth,
                  'y': wrapperHeight,
                },
                'passivePosition': {
                  'x': 0,
                  'y': 0,
                },
                'elementOffset': {
                  'x': 0,
                  'y': 0,
                },
                'itemPosition': {
                  'x': 0,
                  'y': 0,
                },
                'itemDimensions': {
                  'width': windowWidth,
                  'height': windowHeight,
                },
                'elementDimensions': {
                  'width': wrapperWidth,
                  'height': wrapperHeight,
                },
              });
            }
          }
        }}
      >
        {isZoomed ? <ZoomOut className="cursor-pointer" /> : <ZoomIn className="cursor-pointer" />}
      </div>
    </div>
  );
};

export default ZoomableImage;
