import React, { CSSProperties } from 'react';
import { Tween, PlayState } from 'react-gsap';
import { GatsbyImage } from 'gatsby-plugin-image';
import { Maybe, MediaImageBlurredUrl90Fragment } from '../../graphql-types';
interface Props {
  className?: string;
  wrapperClassName?: string;
  image?: Maybe<MediaImageBlurredUrl90Fragment>;
  imageMobile?: Maybe<MediaImageBlurredUrl90Fragment>;
  animated?: boolean;
  style?: CSSProperties;
}

function encodeImageName(imageURL: string) {
  const URLarray = imageURL?.split('/');
  URLarray[URLarray.length - 1] = encodeURIComponent(URLarray[URLarray.length - 1]);
  return URLarray.join('/');
}

const AnimatedImage: React.FC<Props> = ({
  className,
  wrapperClassName,
  image,
  imageMobile,
  animated,
  style,
}) => {
  const [playState, setPlayState] = React.useState(PlayState.play);

  const srcImageURL = React.useMemo(() => {
    return image?.relationships?.field_media_image?.localFile?.publicURL
      ? encodeImageName(image?.relationships?.field_media_image?.localFile?.publicURL)
      : '';
  }, [image]);

  return (
    <div className={`overflow-hidden ${wrapperClassName}`}>
      <Tween playState={playState} from={{ transform: 'scale(1.05)' }} duration={0.5}>
        <div
          onMouseOver={() => animated && setPlayState(PlayState.reverse)}
          onMouseLeave={() => animated && setPlayState(PlayState.play)}
        >
          {image?.relationships?.field_media_image?.localFile?.childImageSharp?.gatsbyImageData ? (
            <div>
              <GatsbyImage
                className={`${className} ${imageMobile ? 'hidden tablet:block' : ''}`}
                style={style}
                image={
                  image?.relationships?.field_media_image?.localFile?.childImageSharp
                    ?.gatsbyImageData
                }
                alt={image?.field_media_image?.alt ?? ''}
              />
              {imageMobile && (
                <GatsbyImage
                  className={`${className} tablet:hidden`}
                  style={style}
                  image={
                    imageMobile?.relationships?.field_media_image?.localFile?.childImageSharp
                      ?.gatsbyImageData
                  }
                  alt={imageMobile?.field_media_image?.alt ?? ''}
                />
              )}
            </div>
          ) : srcImageURL ? (
            <div className={`gatsby-image-wrapper ${className}`}>
              <img
                className={'gif-image'}
                src={srcImageURL}
                alt={image?.field_media_image?.alt ?? ''}
              />
            </div>
          ) : null}
        </div>
      </Tween>
    </div>
  );
};

export default AnimatedImage;
