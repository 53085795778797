import React from 'react';
import ContentLarge from '../contentLarge';
import ArticleAuthor from './articleAuthor';
import { graphql, useStaticQuery } from 'gatsby';
import {
  CompleteArticleToutFieldsFragment,
  GetDefaultArticleToutDataQuery,
} from '../../../graphql-types';
import { GatsbyImage } from 'gatsby-plugin-image';

const GET_DEFAULT_ARTICLE_TOUT_DATA = graphql`
  query GetDefaultArticleToutData {
    nodeFeaturedArticle {
      relationships {
        field_article {
          ...ToutArticleFields
        }
      }
    }
  }
`;

interface Props {
  articleToutItem: CompleteArticleToutFieldsFragment['nodes'][0];
}

const ArticleTout: React.FC<Props> = ({ articleToutItem }) => {
  const data = useStaticQuery<GetDefaultArticleToutDataQuery>(GET_DEFAULT_ARTICLE_TOUT_DATA);

  const customArticleTout = articleToutItem.relationships?.field_article;

  const articleTout = customArticleTout ?? data.nodeFeaturedArticle?.relationships?.field_article;

  const backgroundImage =
    articleTout?.relationships?.field_special_desktop_image?.relationships?.field_media_image
      ?.localFile?.childImageSharp?.gatsbyImageData;

  const backgroundImageMobile =
    articleTout?.relationships?.field_special_mobile_image?.relationships?.field_media_image
      ?.localFile?.childImageSharp?.gatsbyImageData;

  const backgroundColor = articleToutItem.field_art_tout_background_color;

  return (
    <div className="flex mobile:flex-col laptop-standard:flex-row padding-lateral max-width">
      <div className="laptop-standard:w-2/4">
        <GatsbyImage
          image={backgroundImage}
          alt={
            articleTout?.relationships?.field_special_desktop_image?.field_media_image?.alt ?? ''
          }
          className="w-full hidden laptop-standard:block laptop-standard:h-60r desktop-large:h-64r"
        />
        <GatsbyImage
          image={backgroundImageMobile ?? backgroundImage}
          alt={
            articleTout?.relationships?.field_special_desktop_image?.field_media_image?.alt ?? ''
          }
          className="w-full mobile:h-23r laptop-standard:hidden"
        />
      </div>
      <ContentLarge
        title={articleTout?.field_article_title?.processed ?? ''}
        buttonTitle="Read More"
        styles="articleToutButton"
        titleClassContainer="mobile:pb-7 laptop-standard:pb-8 text-white tracking-normal mobile:pt-4 tablet:pt-6 laptop-standard:pt-0 desktop-large:max-w-25 desktop-wider:max-w-18"
        contentType={articleTout?.field_content_type ?? ''}
        internalLink={articleTout?.field_link ?? `/insights/${articleTout?.field_id}`}
        containerStyle={`flex flex-col justify-center mobile:px-6 laptop-standard:w-2/4 laptop-standard:pl-20 laptop-standard:pr-40 py-15 tablet:py-20 desktop-large:py-27 bg-article-tout-${backgroundColor}`}
        type="landing"
      >
        <ArticleAuthor
          landingPage
          profilePhoto={
            articleTout?.relationships?.field_author?.relationships?.field_image?.relationships
              ?.field_media_image?.localFile?.childImageSharp?.gatsbyImageData
          }
          profileAlt={
            articleTout?.relationships?.field_author?.relationships?.field_image?.field_media_image
              ?.alt ?? ''
          }
          author={{
            'title': articleTout?.relationships?.field_author?.title,
            'field_author_profession':
              articleTout?.relationships?.field_author?.field_author_profession,
          }}
          department={articleTout?.field_caption}
          timeToRead={`${articleTout?.field_read_time} min read`}
          className="text-white"
          imageSize="mobile:h-12 mobile:w-12 tablet:h-16 tablet:w-16 laptop-standard:h-16 laptop-standard:w-16 desktop-large:h-20 desktop-large:w-20"
          authorNameClassName="tablet:pt-0 tablet:pl-0"
          classNameImageContainer=""
        />
      </ContentLarge>
    </div>
  );
};

export default ArticleTout;
