import React from 'react';
import AnimatedImage from '../AnimatedImage';
import Button from '../button';
import { Tout5050Props } from './props';
import ReactHtmlParser from 'react-html-parser';
import { bgColors } from '../../constants';

const Tout5050: React.FC<Tout5050Props> = ({ tout5050Item, className }) => {
  return (
    <div className={`tout5050 container max-width ${className}`}>
      <div
        className={`flex padding-lateral mobile:flex-col laptop-standard:flex-row ${
          tout5050Item.field_arrangement_tout
            ? 'alignment_image_'.concat(tout5050Item.field_arrangement_tout)
            : ''
        }`}
      >
        <div
          className={`${
            bgColors[tout5050Item.field_main_background_color ?? 'noche']
          } text-white px-9 py-14 laptop-standard:w-1/2 flex flex-col justify-center laptop-standard:items-center laptop-standard:min-h-42r`}
        >
          <div className="laptop-standard:w-4/5 laptop-wide:w-3/4">
            <h4>{tout5050Item.title}</h4>
            <p className="opacity-80 my-6">{tout5050Item.field_caption}</p>
            <div className="mb-5 text-base laptop-standard:mb-10 laptop-standard:text-lg">
              {ReactHtmlParser(tout5050Item.body?.processed ?? '')}
            </div>
            <Button
              styles="!px-5 !py-5 laptop-standard:!px-7 text-orange-600 !font-bold"
              buttonTitle={tout5050Item.field_button_title}
              contentType={tout5050Item.field_button_type}
              internalLink={tout5050Item.field_link}
              externalLink={tout5050Item.field_link}
            />
          </div>
        </div>
        <div
          className={`laptop-standard:w-1/2 flex items-center ${
            bgColors[tout5050Item.field_main_background_color ?? 'noche']
          }`}
        >
          {tout5050Item.relationships?.field_image ? (
            <AnimatedImage
              image={tout5050Item.relationships?.field_image}
              wrapperClassName="w-full"
            />
          ) : tout5050Item.field_copy?.processed ? (
            <div className="flex justify-center items-end laptop-standard:items-center h-full w-full text-white pt-14 laptop-standard:pt-0">
              {ReactHtmlParser(tout5050Item.field_copy?.processed)}
            </div>
          ) : (
            ''
          )}
        </div>
      </div>
    </div>
  );
};

export default Tout5050;
