import React from 'react';
import ThreeColumns from './threeColumns';
import EmbeddedVideo from './video/embeddedVideo';
import Container from './container';
import ReactHtmlParser from 'react-html-parser';
import { CompleteContentAdvertiseFieldsFragment } from '../../graphql-types';
import { GatsbyImage } from 'gatsby-plugin-image';

interface Props {
  contentAdItem: CompleteContentAdvertiseFieldsFragment['nodes'][0];
}
const ContentAdvertise: React.FC<Props> = ({ contentAdItem }) => {
  const title = contentAdItem.field_custom_title?.processed ?? contentAdItem.title ?? '';
  const threeColumnTitle = contentAdItem.field_columns_content?.[0]?.processed ?? '';
  const threeColumnBody = contentAdItem.field_columns_content?.[1]?.processed ?? '';
  const logo =
    contentAdItem.relationships?.field_image?.relationships?.field_media_image?.localFile
      ?.childImageSharp?.gatsbyImageData;
  const mainImage =
    contentAdItem.relationships?.field_article_image?.relationships?.field_media_image?.localFile
      ?.childImageSharp?.gatsbyImageData;
  const mainImagePublicURL =
    contentAdItem.relationships?.field_article_image?.relationships?.field_media_image?.localFile
      ?.publicURL;
  const videoURL = contentAdItem.field_video_url;

  const image =
    mainImage || mainImagePublicURL ? (
      mainImage ? (
        <GatsbyImage
          image={mainImage}
          className="mt-18 margin-lateral"
          imgClassName="w-full"
          alt={contentAdItem.relationships?.field_article_image?.field_media_image?.alt ?? ''}
        />
      ) : (
        <img
          className="w-full mt-18 padding-lateral "
          src={mainImagePublicURL ?? ''}
          alt={contentAdItem.relationships?.field_article_image?.field_media_image?.alt ?? ''}
        />
      )
    ) : (
      <></>
    );
  const threeColumns =
    threeColumnTitle || threeColumnBody ? (
      <ThreeColumns
        title={ReactHtmlParser(threeColumnTitle)}
        body={ReactHtmlParser(threeColumnBody)}
        images={logo}
        imgAlt={contentAdItem.relationships?.field_image?.field_media_image?.alt ?? ''}
      />
    ) : (
      <></>
    );

  const video = videoURL ? <EmbeddedVideo videoURL={videoURL} /> : <></>;
  return (
    <Container>
      <h1
        className="pt-18 laptop-standard:pt-27 text-center font-black
        laptop-standard:leading-17 px-6 laptop-standard:w-4/5 m-auto"
      >
        {ReactHtmlParser(title)}
      </h1>
      {image}
      {video}
      {threeColumns}
    </Container>
  );
};

export default ContentAdvertise;
