import React, { useRef } from 'react';
import ReactHtmlParser from 'react-html-parser';
import ElasticCarousel, { RenderArrowProps } from 'react-elastic-carousel';
import left from '../../images/carousel-icons/left-arrow.png';
import right from '../../images/carousel-icons/right-arrow.png';
import { GatsbyImage } from 'gatsby-plugin-image';
import './_carouselSmall.css';
import { CompleteCarouselFieldsFragment } from '../../../graphql-types';
import useOnScreen from '../../utils/useOnScreen';

interface Props {
  carouselItems: CompleteCarouselFieldsFragment['nodes'][0];
}

const myArrow = (params: RenderArrowProps) => {
  const display = params.isEdge ? 'hidden' : 'block';
  const pointer =
    params.type === 'PREV' ? (
      <img src={left} alt="see previous" />
    ) : (
      <img src={right} alt="see next" />
    );
  return (
    <div
      className="flex self-end laptop-standard:self-center"
      style={{ width: '60px', height: '60px' }}
    >
      <button
        onClick={params.onClick}
        className={`${display} self-end laptop-standard:self-center`}
      >
        {pointer}
      </button>
    </div>
  );
};

const CarouselSmall: React.FC<Props> = ({ carouselItems }) => {
  const ref = useRef<HTMLDivElement>(null);
  const isVisible = useOnScreen(ref);

  return (
    <div ref={ref} className="carousel-container">
      <ElasticCarousel
        renderArrow={myArrow}
        pagination={false}
        enableAutoPlay={isVisible}
        autoPlaySpeed={5000}
        transitionMs={700}
        isRTL={false}
      >
        {carouselItems.relationships?.field_carousel_items?.map((slider, index) => {
          return (
            <div
              className="carousel-body space-y-5 laptop-standard:space-y-0"
              key={`carousel-${index}`}
            >
              <div className="flex-none w-full laptop-standard:w-2/12">
                <GatsbyImage
                  image={
                    slider?.relationships?.field_image?.relationships?.field_media_image?.localFile
                      ?.childImageSharp?.gatsbyImageData
                  }
                  className="w-1/2 mx-auto laptop-standard:mx-0 laptop-standard:w-full"
                  alt={slider?.relationships?.field_image?.field_media_image?.alt ?? ''}
                />
              </div>
              <div
                className="flex-initial w-full mx-auto laptop-standard:mx-0 pb-10
                laptop-standard:pb-0 laptop-standard:w-8/12 laptop-standard:pl-10"
              >
                {ReactHtmlParser(slider?.field_copy?.processed ?? '')}
              </div>
            </div>
          );
        })}
      </ElasticCarousel>
    </div>
  );
};

export default CarouselSmall;
