import React from 'react';
import { Link as GatsbyLink } from 'gatsby';

interface Props {
  to?: string | null;
  type?: 'internal' | 'external';
  accessibilityLabel: string;
  children: React.ReactNode;
}

const LinkTo: React.FC<Props> = ({ to, type, accessibilityLabel, children }) => {
  if (!to) {
    return <>{children}</>;
  }
  return type === 'internal' ? (
    <GatsbyLink to={to} title={accessibilityLabel}>
      {children}
    </GatsbyLink>
  ) : (
    <a href={to} target="_blank" rel="noreferrer" aria-label={accessibilityLabel}>
      {children}
    </a>
  );
};

export default LinkTo;
