import React from 'react';
import './_carousel.css';
import { CompleteCarouselFieldsFragment } from '../../../graphql-types';
import CarouselSmall from './carouselSmall';
import CarouselHero from './carouselHero';

interface Props {
  carouselItems: CompleteCarouselFieldsFragment['nodes'][0];
  position: number;
}
const Carousel: React.FC<Props> = ({ carouselItems, position }) => {
  if (carouselItems.field_carousel_type === 'hero') {
    return <CarouselHero carouselItems={carouselItems} position={position} />;
  }
  return <CarouselSmall carouselItems={carouselItems} />;
};

export default Carousel;
