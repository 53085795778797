import React from 'react';
import { CompleteSimpleMediaFieldsFragment } from '../../graphql-types';
import Container from './container';
import ContentImage from './contentImage';
import EmbeddedVideo from './video/embeddedVideo';

interface Props {
  simpleMediaItem: CompleteSimpleMediaFieldsFragment['nodes'][0];
}

const SimpleMedia: React.FC<Props> = ({ simpleMediaItem }) => {
  const videoURL = simpleMediaItem.field_video_url;
  const mediaSize = simpleMediaItem.field_media_size;

  const image =
    simpleMediaItem.relationships?.field_media?.relationships?.field_media_image?.localFile
      ?.childImageSharp?.gatsbyImageData;

  if (image) {
    return (
      <ContentImage
        altImg={simpleMediaItem.relationships?.field_media?.field_media_image?.alt ?? ''}
        backgroundImage={image}
        backgroundImageMobile={image}
        backgroundColorLazy="bg-white"
        styles={
          mediaSize === 'small'
            ? 'mobile:h-26r laptop-large:h-40r desktop-large:h-64r'
            : 'mobile:h-36r laptop-standard:h-64r desktop-large:h-84r'
        }
      />
    );
  }

  if (videoURL) {
    return (
      <Container>
        <EmbeddedVideo videoURL={videoURL} />
      </Container>
    );
  }

  return <></>;
};

export default SimpleMedia;
