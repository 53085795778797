import React, { useCallback, useEffect, useMemo } from 'react';
import { CompleteParagraphColumnsFragment } from '../../../graphql-types';
import PageBuilder from '../../pageBuilders/pageBuilder';
import useWindowSize from '../../utils/useWindowSize';
import ColumnsContext from './columnsContext';

interface Props {
  id: string;
  position: number;
  columnsParagraph: CompleteParagraphColumnsFragment;
}

const Columns: React.FC<Props> = ({ id, columnsParagraph, position }) => {
  const { width } = useWindowSize();
  const numberOfColumns = parseInt(columnsParagraph.field_number_of_columns ?? '2', 10);
  const arrangement = columnsParagraph.field_arrangement?.split('-');
  const theme = columnsParagraph.field_columns_theme;
  const style = columnsParagraph.field_columns_style;
  let themeClass = '';

  if (theme === 'Gray') {
    themeClass =
      'bg-gray-550 border laptop-standard-max:!p-7 laptop-standard:py-14 border-gray-450 no-border-x laptop-standard-max:text-center laptop-standard-max:justify-items-center';
  } else if (theme === 'Black') {
    themeClass =
      'bg-black text-white laptop-standard-max:p-10 laptop-standard-max:pt-4 mobile-text-md';
  }

  let styleClass = '';
  if (style === 'full') {
    styleClass = '';
  } else {
    styleClass = 'padding-lateral laptop-standard-max:m-0 laptop-standard-max:p-0';
  }

  let arrangementStyle = '';
  if (numberOfColumns === 2 && arrangement?.length === 2) {
    arrangementStyle = `laptop-standard:grid-cols-${columnsParagraph.field_arrangement}`;
    if (columnsParagraph.field_arrangement === '80-20') {
      arrangementStyle = 'laptop-standard:grid-cols-70-30 laptop-large:grid-cols-80-20';
    }
  }

  const columnsSpacing = useMemo(() => {
    for (let iter = 0; iter < (columnsParagraph.relationships?.field_items?.length ?? 0); iter++) {
      if (
        columnsParagraph.relationships?.field_items?.[iter]?.__typename !==
        'paragraph__media_grid_item'
      ) {
        return 'laptop-standard:gap-x-20 laptop-large:gap-x-25';
      }
      return 'laptop-standard:gap-x-3';
    }
  }, [columnsParagraph.relationships?.field_items]);

  const isMobileInverted = columnsParagraph.field_mobile_inverted;

  const columnsId = `unique-columns-${id}`;

  const areColumnsOfTitleAndCopy = useMemo(() => {
    for (let iter = 0; iter < (columnsParagraph.relationships?.field_items?.length ?? 0); iter++) {
      if (
        columnsParagraph.relationships?.field_items?.[iter]?.__typename !== 'paragraph__title_copy'
      ) {
        return false;
      }
    }
    return true;
  }, [columnsParagraph.relationships?.field_items]);

  const alignTextTitleVertically = useCallback(() => {
    let maxHeight = 0;
    const titleCopies = document.querySelectorAll(`#${columnsId} .title-copy-title`);
    titleCopies.forEach((titleCopyItem) => {
      (titleCopyItem as any).style.minHeight = 'initial';
      if (titleCopyItem.clientHeight > maxHeight) {
        maxHeight = titleCopyItem.clientHeight;
      }
    });

    titleCopies.forEach((titleCopyItem) => {
      (titleCopyItem as any).style.minHeight = `${maxHeight}px`;
    });
  }, [columnsId]);

  useEffect(() => {
    if (areColumnsOfTitleAndCopy) {
      setTimeout(alignTextTitleVertically, 200);
    }
  }, [width, areColumnsOfTitleAndCopy, columnsId, alignTextTitleVertically]);

  return (
    <ColumnsContext.Provider
      value={{
        usedInColumns: true,
        columns: `${columnsParagraph.field_arrangement}-${numberOfColumns}`,
      }}
    >
      <div
        id={columnsId}
        className={`grid gap-y-15 ${styleClass} ${columnsSpacing} ${
          numberOfColumns !== 1 ? 'laptop-standard:gap-y-25' : ''
        } max-width ${numberOfColumns === 2 ? 'laptop-standard:items-center' : ''} ${
          arrangementStyle ? arrangementStyle : `laptop-standard:grid-cols-${numberOfColumns}`
        } ${themeClass}`}
      >
        {columnsParagraph.relationships?.field_items?.map((paragraphItem, itemIndex) => {
          let shouldBeHidden = false;
          if (paragraphItem?.__typename === 'paragraph__spacer') {
            shouldBeHidden = true;
          }
          return (
            <PageBuilder
              id={`${id}-${itemIndex}`}
              key={`pb-component-${itemIndex ?? 'unique'}`}
              position={position}
              className={`${
                shouldBeHidden ? 'hidden laptop-standard:block' : ''
              } laptop-standard-max:order-${
                isMobileInverted
                  ? (columnsParagraph.relationships?.field_items?.length ?? 0) - itemIndex
                  : itemIndex + 1
              }`}
              items={{ relationships: { 'field_body_items': [paragraphItem] } }}
            />
          );
        })}
      </div>
    </ColumnsContext.Provider>
  );
};

export default Columns;
