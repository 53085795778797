import { GatsbyImage } from 'gatsby-plugin-image';
import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import ContentImage from '../../contentImage';
import ContentLarge from '../../contentLarge';
import { ToutTextProps } from '../props';
import * as styles from '../../../styles/toutText.module.css';

const ToutTextImageLink: React.FC<ToutTextProps> = ({ toutTextItem }) => {
  return (
    <ContentImage
      backgroundColorLazy="bg-purple-700"
      textColor="text-yellow-300"
      styles={toutTextItem.field_inverted_order ? styles.toutTextImageInverted : ''}
    >
      <ContentLarge
        title={toutTextItem.title ?? ''}
        body={ReactHtmlParser(toutTextItem.body?.processed ?? '')}
        buttonTitle={toutTextItem.field_button_title ?? ''}
        styles="laptop-standard:h-12 toutTextImageButton"
        contentType={'internalLink'}
        internalLink={toutTextItem.field_link ?? ''}
        titleClassContainer="format-for-content-title-large text-black mb-10"
        bodyStyle="body1 text-black laptop-standard:w-2/3"
        containerStyle={`tablet:pt-15 laptop-standard:pt-32 ${styles.toutTextImageContentLarge}`}
      />
      <div className="container-for-image-content-large">
        <GatsbyImage
          image={
            toutTextItem.relationships?.field_image?.relationships?.field_media_image?.localFile
              ?.childImageSharp?.gatsbyImageData
          }
          alt={toutTextItem.relationships?.field_image?.field_media_image?.alt ?? ''}
        />
      </div>
    </ContentImage>
  );
};

export default ToutTextImageLink;
