import React from 'react';
import Body from '../../article/body';
import ParagraphArticles from '../../article/paragraphArticle';
import TextIntro from '../../article/textIntro';
import { ToutTextProps } from '../props';

const ToutTextSimple: React.FC<ToutTextProps> = ({ toutTextItem }) => {
  return (
    <Body>
      <TextIntro
        content={toutTextItem.field_custom_title?.processed ?? toutTextItem.title ?? ''}
        styles="padding-text-intro-about-us foreword-text"
      />
      <ParagraphArticles
        sections={[{ text: toutTextItem.body?.processed ?? '' }]}
        classBaseContainer="mt-0"
        classNameDesk="foreword-body-text"
      />
    </Body>
  );
};

export default ToutTextSimple;
