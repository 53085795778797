import React, { useContext } from 'react';
import ReactHtmlParser from 'react-html-parser';
import { CompleteParagraphMediaGridItemFieldsFragment } from '../../../graphql-types';
import AnimatedImage from '../AnimatedImage';
import { ButtonProps } from '../button/buttonProps';
import LinkTo from '../linkTo';
import Button from './Button';
import ColumnsContext from './columnsContext';

interface Props {
  index: number;
  mediaItemParagraph?: CompleteParagraphMediaGridItemFieldsFragment | null;
  titleClass?: string;
  showIcon?: boolean;
  buttonType?: ButtonProps['buttonType'];
  buttonStyles?: ButtonProps['wrapperStyles'];
}

const MediaGridItem: React.FC<Props> = ({
  index,
  mediaItemParagraph,
  titleClass = '',
  buttonStyles,
  buttonType,
  showIcon,
}) => {
  const { usedInColumns } = useContext(ColumnsContext);

  const firstLink = mediaItemParagraph?.relationships?.field_links?.[0];

  return mediaItemParagraph ? (
    <div
      className={`flex flex-col media-grid-item ${usedInColumns ? 'h-full' : ''}`}
      key={`mediaGrid-item-${index}`}
    >
      <LinkTo
        to={firstLink?.field_cta_link}
        accessibilityLabel={
          mediaItemParagraph.field_title ??
          mediaItemParagraph.relationships?.field_media?.field_media_image?.alt ??
          ''
        }
        type={firstLink?.field_cta_type === 'internalLink' ? 'internal' : 'external'}
      >
        <div className="h-auto w-full">
          <AnimatedImage image={mediaItemParagraph?.relationships?.field_media} animated />
        </div>
      </LinkTo>
      <div className={`flex flex-col ${usedInColumns ? 'content-media-grid-item h-full' : ''}`}>
        {mediaItemParagraph?.field_title && (
          <h6 className={`py-5 laptop-standard-max:text-3lg ${titleClass}`}>
            {mediaItemParagraph?.field_title}
          </h6>
        )}
        {mediaItemParagraph?.field_copy?.processed &&
          ReactHtmlParser(mediaItemParagraph?.field_copy?.processed)}
        {mediaItemParagraph?.relationships?.field_links?.map((link, indexLink) => {
          let buttonTypeValue: ButtonProps['buttonType'] = buttonType ?? 'default';
          if (link?.field_style === 'Text') {
            buttonTypeValue = 'text';
          }
          return (
            link && (
              <Button
                key={indexLink}
                index={indexLink}
                linkParagraph={link}
                customKey={`button-${index}-${indexLink}`}
                customClassName={indexLink !== 0 ? '!pt-0' : ''}
                customStyles="cta-link-format cta-link-hover-orange cta-svg-absolute"
                customTextStyle="laptop-standard-max:text-xs"
                customButtonType={buttonType ?? buttonTypeValue}
                customWrapperStyles={`${buttonStyles} button-media-grid-item`}
              />
            )
          );
        })}
      </div>
    </div>
  ) : null;
};

export default MediaGridItem;
