import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import RenderVideo from './video/renderVideo';
import Eyebrows from './complements/eyebrows';
import Button from './button';
import * as style from './tout.module.css';
import { BgImage } from 'gbimage-bridge';

// Assets
import dots from '../images/icons/three_dots_orange.svg';
import dotsWhite from '../images/icons/three_dots_white.svg';
import videoPlayIcon from '../images/icons/video.svg';
import { CompleteToutFieldsFragment } from '../../graphql-types';

interface Props {
  toutItem: CompleteToutFieldsFragment['nodes'][0];
}

enum ContentType {
  'internalLink' = 'internalLink',
  'externalLink' = 'externalLink',
  'video' = 'video',
}

const Tout: React.FC<Props> = ({ toutItem }) => {
  const backgroundVideo = toutItem.relationships?.field_video
    ? toutItem.relationships.field_video.relationships?.field_media_video_file?.localFile?.publicURL
    : null;
  const backgroundVideoMobile = toutItem.relationships?.field_mobile_video
    ? toutItem.relationships.field_mobile_video.relationships?.field_media_video_file?.localFile
        ?.publicURL
    : null;
  const backgroundImage =
    toutItem.relationships?.field_image?.relationships?.field_media_image?.localFile;
  const backgroundImageMobile =
    toutItem.relationships?.field_mobile_image?.relationships?.field_media_image?.localFile;
  const contentType = toutItem.field_content_type;
  const eyebrows = toutItem.field_eyebrows;
  const title = toutItem.title;
  const videoId = toutItem.field_video_id;
  const link = toutItem.field_link;
  const bodyContent = toutItem.body?.value;
  const buttonTitle = toutItem.field_button_title;
  const isContentLeft = toutItem.field_content_position === 'left';
  const body = bodyContent ? ReactHtmlParser(bodyContent) : bodyContent;

  const video = backgroundVideo ? (
    <RenderVideo loop video={backgroundVideo} backgroundImage={backgroundImage?.publicURL} />
  ) : (
    <></>
  );
  const videoMobile = backgroundVideoMobile ? (
    <RenderVideo
      loop
      video={backgroundVideoMobile}
      backgroundImage={backgroundImageMobile?.publicURL}
    />
  ) : (
    <></>
  );

  const titleComponent = React.useMemo(() => {
    switch (contentType) {
      case ContentType.internalLink:
      case ContentType.externalLink:
        return (
          <h2 className={'mobile:pb-7 laptop-standard:pb-13 text-white tracking-normal'}>
            {title}
          </h2>
        );
      default:
        return <h1 className={style.title}>{title}</h1>;
    }
  }, [contentType, title]);

  const bodyComponent = React.useMemo(() => {
    if (backgroundVideo) {
      return <p className={style.body}>{body}</p>;
    }
    return (
      <div className={'body1 text-white laptop-standard:w-2/3 laptop-standard:pb-12'}>{body}</div>
    );
  }, [backgroundVideo, body]);

  const icon = React.useMemo(() => {
    switch (contentType) {
      case ContentType.internalLink:
      case ContentType.externalLink:
        return toutItem.field_button_background_color === 'orange' ? dotsWhite : dots;
      case 'video':
        return videoPlayIcon;
      default:
        return dots;
    }
  }, [contentType, toutItem.field_button_background_color]);

  const buttonBackgroundColor = React.useMemo(() => {
    switch (toutItem.field_button_background_color) {
      case 'orange':
        return 'bg-orange-600';
      case 'white':
        return '';
      default:
        return '';
    }
  }, [toutItem.field_button_background_color]);

  const buttonTextColor = React.useMemo(() => {
    switch (toutItem.field_button_text_color) {
      case 'black':
        return '';
      case 'white':
        return 'text-white';
      default:
        return '';
    }
  }, [toutItem.field_button_text_color]);

  const eyebrowsTextColor = React.useMemo(() => {
    switch (toutItem.field_eyebrows_color) {
      case 'yellow':
        return 'text-yellow-300';
      case 'white':
        return 'text-white';
      default:
        return '';
    }
  }, [toutItem.field_eyebrows_color]);

  return (
    <>
      <BgImage
        image={backgroundImage?.childImageSharp?.gatsbyImageData}
        alt={toutItem.relationships?.field_image?.field_media_image?.alt ?? ''}
        {...{ className: style.videoContainer }}
      >
        {video}
        <div
          className={style.contentPosition}
          style={{ position: videoId && backgroundVideo ? 'absolute' : 'relative' }}
        >
          <div
            className={`container max-width pt-13 pb-13 laptop-standard:pb-0
            desktop-large:pb-13 padding-lateral`}
          >
            <Eyebrows
              eyebrow1={eyebrows?.[0] ?? ''}
              eyebrow2={eyebrows?.[1] ?? ''}
              textColor={eyebrowsTextColor}
            />
          </div>
          <div
            className={`container max-width pt-0 desktop-large:pt-20
            desktop-wider:pt-56 flex justify-between padding-lateral ${
              contentType === ContentType.internalLink || contentType === ContentType.externalLink
                ? 'content-image-children'
                : ''
            }`}
          >
            {isContentLeft ? null : <div className="w-7/12" />}
            <div className="w-6/12 desktop-standard:pr-0">
              {titleComponent}
              {bodyComponent}
              <Button
                buttonTitle={buttonTitle}
                icon={icon}
                styles={`${buttonTextColor} ${buttonBackgroundColor} laptop-standard:h-12`}
                contentType={contentType}
                internalLink={link}
                externalLink={link}
                videoId={videoId}
              />
            </div>
            {!isContentLeft ? null : <div className="w-7/12" />}
          </div>
        </div>
      </BgImage>
      <BgImage
        image={backgroundImageMobile?.childImageSharp?.gatsbyImageData}
        alt={toutItem.relationships?.field_mobile_image?.field_media_image?.alt ?? ''}
        {...{ className: style.videoContainerMobile }}
      >
        {videoMobile}
        <div
          className={style.contentPosition}
          style={{ position: videoId && backgroundVideoMobile ? 'absolute' : 'relative' }}
        >
          <div className="container max-width pt-6 pb-6 padding-lateral">
            <Eyebrows
              eyebrow1={eyebrows?.[0] ?? ''}
              eyebrow2={eyebrows?.[1] ?? ''}
              textColor={eyebrowsTextColor}
            />
          </div>
          <div
            className={`container max-width ${style.topPadding} space-y-6 padding-lateral mobile:pb-20`}
          >
            {titleComponent}
            {bodyComponent}
            <Button
              buttonTitle={buttonTitle}
              icon={icon}
              styles={`${buttonTextColor} ${buttonBackgroundColor} laptop-standard:h-12`}
              contentType={contentType}
              internalLink={link}
              externalLink={link}
              videoId={videoId}
            />
          </div>
        </div>
      </BgImage>
    </>
  );
};

export default Tout;
