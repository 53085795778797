import React from 'react';
import Container from './container';

interface Props {
  backgroundColor?: string;
  paddingLateralStyle?: string;
  styles?: string;
}
const TwoColumns: React.FC<Props> = (props) => {
  const paddingLateralStyle = props.paddingLateralStyle ?? 'padding-lateral';
  const backgroundColor = props.backgroundColor ?? 'bg-transparent';
  return (
    <div className={`${props.styles ?? ''} ${backgroundColor}`}>
      <Container>
        <div
          className={`${paddingLateralStyle} laptop-standard:space-y-0 laptop-standard:flex laptop-standard:justify-between ${props.backgroundColor}`}
        >
          {props.children}
        </div>
      </Container>
    </div>
  );
};

export default TwoColumns;
