import React, { Component, ReactNode } from 'react';
import ReactHtmlParser from 'react-html-parser';

interface Props {
  classBaseContainer?: string;
  classNameMobile?: string;
  classNameDesk?: string;
  classContainer?: string;
  classTitle?: string;
  sections: {
    title?: string;
    text?: string;
  }[];
}

class ParagraphArticles extends Component<Props> {
  render(): ReactNode {
    const {
      classBaseContainer,
      classContainer = 'space-y-10',
      classTitle,
      classNameMobile = 'body1 laptop-standard:mx-32 mobile:pr-3',
      classNameDesk = 'body2 laptop-standard:mx-32 mobile:pr-3',
      sections,
    } = this.props;

    const paragraph = (
      <>
        {sections.map((section, index) => {
          return (
            <div key={`paragraph-${index}`}>
              <div className={classContainer}>
                <h5 className={classTitle ?? ''}>{section.title ?? ''}</h5>
                {section.text && (
                  <>
                    <div className={`${classNameDesk} mobile:hidden laptop-standard:block`}>
                      {ReactHtmlParser(section.text)}
                    </div>
                    <div className={`${classNameMobile} mobile:block laptop-standard:hidden`}>
                      {ReactHtmlParser(section.text)}
                    </div>
                  </>
                )}
              </div>
            </div>
          );
        })}
      </>
    );
    return <div className={classBaseContainer}>{paragraph}</div>;
  }
}

export default ParagraphArticles;
