import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import { CompleteParagraphTitleCopyFieldsFragment } from '../../../graphql-types';
import Button from './Button';
import ColumnsContext from './columnsContext';

interface Props {
  index: number;
  position: number;
  titleCopyParagraph: CompleteParagraphTitleCopyFieldsFragment;
}

const TitleCopy: React.FC<Props> = ({ index, position, titleCopyParagraph }) => {
  const { usedInColumns } = React.useContext(ColumnsContext);

  const style = titleCopyParagraph.field_title_copy_style;
  const theme = titleCopyParagraph.field_title_copy_theme;

  const title = titleCopyParagraph.field_title;
  const description = titleCopyParagraph.field_copy?.processed;
  const links = titleCopyParagraph.relationships?.field_links;

  const textWidth =
    usedInColumns || style === 'full'
      ? ''
      : 'padding-lateral laptop-standard:w-4/5 laptop-standard-max:m-0 laptop-standard-max:p-0';

  const themeClass =
    theme === 'Yellow' ? 'text-yellow-1000 text-center flex flex-col items-center' : '';

  const titleClass = usedInColumns
    ? ''
    : 'text-4lg leading-7 laptop-standard:text-7lg laptop-standard:leading-9';

  const bodyClass = usedInColumns ? 'title-copy-paragraph-columns' : 'title-copy-larger-paragraph';

  return (
    <div className={`${position === 0 ? textWidth : `${textWidth}`} ${themeClass} ${bodyClass}`}>
      {title && (
        <h6
          className={`title-copy-title ${
            description || links?.length ? 'mb-2 laptop-standard:mb-8' : ''
          } font-bold ${titleClass}`}
        >
          {title}
        </h6>
      )}
      {description && <div className="title-copy-body">{ReactHtmlParser(description ?? '')}</div>}
      {links?.map(
        (link, linkIndex) => link && <Button key={index} index={linkIndex} linkParagraph={link} />
      )}
    </div>
  );
};

export default TitleCopy;
