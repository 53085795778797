import externalLink from '../../../images/icons/external_link.svg';
import videoSmall from '../../../images/icons/videoSmall.svg';
import video from '../../../images/icons/video.svg';
import internalLink from '../../../images/icons/internal_link.svg';

function getIconForContentType(contentType?: string | null, old?: boolean): any {
  switch (contentType) {
    case 'externalLink':
      return externalLink;
    case 'video':
      if (old) {
        return video;
      }
      return videoSmall;
    case 'internalLink':
      return internalLink;
    default:
      return '';
  }
}

export default getIconForContentType;
