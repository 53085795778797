import React from 'react';
import { CompleteParagraphLinkFieldsFragment } from '../../../graphql-types';
import { default as ButtonComponent } from '../button';
import { ButtonProps } from '../button/buttonProps';
import ctaIconHandler from '../button/complements/cta-icon-handler';

interface Props {
  index: number;
  customKey?: string;
  linkParagraph: CompleteParagraphLinkFieldsFragment;
  customClassName?: string;
  customButtonType?: ButtonProps['buttonType'];
  customWrapperStyles?: ButtonProps['wrapperStyles'];
  customShowIcon?: boolean;
  customStyles?: string;
  customTextStyle?: string;
}

const Button: React.FC<Props> = ({
  index,
  linkParagraph,
  customButtonType,
  customWrapperStyles,
  customTextStyle,
  customClassName,
  customShowIcon,
  customStyles,
  customKey,
}) => {
  let buttonClass = customClassName ?? '';
  if (linkParagraph.field_button_theme === 'Black') {
    buttonClass += ' all-button-quick-links';
  }
  switch (linkParagraph.field_alignment) {
    case 'Right':
      buttonClass += ' text-right';
      break;
    case 'Center':
      buttonClass += ' text-center';
      break;
    default:
      break;
  }
  if (linkParagraph.field_font_weight === 'Bold') {
    buttonClass += ' font-bold';
  }
  if (linkParagraph.field_show_underline) {
    buttonClass += ' underline';
  }

  let buttonType: ButtonProps['buttonType'] = 'default';
  if (linkParagraph.field_style === 'Text') {
    buttonType = 'text';
  }
  return (
    <ButtonComponent
      wrapperStyles={customWrapperStyles ?? buttonClass}
      className={buttonClass}
      icon={
        customShowIcon || linkParagraph.field_show_icon
          ? ctaIconHandler(linkParagraph.field_cta_type, true)
          : null
      }
      styles={customStyles}
      textStyle={customTextStyle}
      buttonTitle={linkParagraph.field_cta_label}
      internalLink={linkParagraph.field_cta_link}
      externalLink={linkParagraph.field_cta_link}
      contentType={linkParagraph.field_cta_type}
      buttonType={customButtonType ?? buttonType}
      videoId={linkParagraph.field_vimeo_id}
    />
  );
};

export default Button;
