import React, { useState } from 'react';
import { graphql, Link, useStaticQuery } from 'gatsby';
import Button from '../button';
import ArticleCard from '../articleCard';
import {
  CompleteArticlesGridFieldsFragment,
  CompleteArticleToutFieldsFragment,
  GetLastArticlesQuery,
} from '../../../graphql-types';
import DownArrow from '../../images/icons/DownArrow.svg';

const containerClass = 'grid grid-cols-1 laptop-standard:grid-cols-3 laptop-standard:gap-4';

const GET_LAST_ARTICLES = graphql`
  query GetLastArticles {
    allNodeArticle(
      sort: { fields: [field_date], order: DESC }
      filter: { field_hidden: { ne: true } }
    ) {
      ...TileArticlesFields
    }
    nodeFeaturedArticle {
      relationships {
        field_article {
          ...ToutArticleFields
        }
      }
    }
  }
`;

interface Props {
  config: CompleteArticlesGridFieldsFragment['nodes'][0];
  exclude?: CompleteArticleToutFieldsFragment[];
}

const ArticlesGrid: React.FC<Props> = ({ config, exclude }) => {
  const {
    title: articlesGridTitle,
    'field_initial_items_displayed': articlesDisplayed,
    'field_show_load_more_': showLoadMore,
    'field_show_header': showHeader,
  } = config;

  const [articlesToShow, setArticlesToShow] = useState(articlesDisplayed);

  const {
    allNodeArticle: { nodes: articles },
    nodeFeaturedArticle,
  } = useStaticQuery<GetLastArticlesQuery>(GET_LAST_ARTICLES);

  const excludedArticles =
    exclude?.map(
      (article) =>
        article.nodes?.[0].relationships?.field_article?.field_id ??
        nodeFeaturedArticle?.relationships?.field_article?.field_id
    ) ?? [];

  const nonTouttArticles = articles.filter(
    (article) => !excludedArticles.includes(article.field_id)
  );

  const articlesData = articlesToShow
    ? nonTouttArticles.slice(0, articlesToShow)
    : nonTouttArticles;

  return (
    <>
      {showHeader && (
        <div
          className={`container max-width padding-lateral ${
            showHeader ? 'mobile:pt-5 mobile:mt-10' : ''
          }`}
        >
          <hr className="border-t-2 border-gray-300" />
        </div>
      )}
      <div
        className={`container max-width padding-lateral laptop-standard:pt-27 ${
          !showHeader ? 'mobile:pt-5 mobile:mt-10' : ''
        }`}
      >
        {showHeader ? (
          <div className="grid laptop-standard:grid-cols-12 pb-13">
            <div className="col-start-1 text-2lg laptop-standard:text-4lg leading-3 font-black tracking-normal">
              {articlesGridTitle}
            </div>
            <div className="col-start-12 text-right">
              <Link
                to="/insights"
                className="orange-link leading-normal font-black tracking-normal text-base"
              >
                View All
              </Link>
            </div>
          </div>
        ) : null}
        <div className={containerClass}>
          {articlesData.map((articleItem, index) => {
            const {
              title,
              'field_id': fieldId,
              'field_link': fieldLink,
              'field_caption': fieldCaption,
              'field_content_type': fieldContentType,
              'field_read_time': fieldReadTime,
              relationships,
            } = articleItem;
            return (
              <ArticleCard
                key={`article-${index}`}
                buttonTitle={'Read More'}
                title={title}
                image={relationships?.field_image}
                author={relationships?.field_author?.title}
                profession={relationships?.field_author?.field_author_profession}
                section={fieldCaption}
                timeToRead={fieldReadTime}
                contentType={fieldContentType}
                internalLink={fieldLink ?? `/insights/${fieldId}`}
              />
            );
          })}
        </div>
        {showLoadMore &&
          articlesDisplayed &&
          articlesToShow &&
          articlesToShow < nonTouttArticles.length && (
            <Button
              contentType="loadItems"
              icon={DownArrow}
              handleClick={() =>
                setArticlesToShow((value) => {
                  return value ? value + articlesDisplayed : articlesDisplayed;
                })
              }
              linkTitle="Load More"
              styles="container mx-auto w-40 pt-10 pb-20"
            />
          )}
      </div>
    </>
  );
};

export default ArticlesGrid;
