import React from 'react';
import Button from './button';
import AnimatedImage from './AnimatedImage';
import { Maybe, MediaImageBlurredUrl90Fragment } from '../../graphql-types';
import { Link } from 'gatsby';
import LinkTo from './linkTo';

interface Props {
  title?: Maybe<string>;
  author?: Maybe<string>;
  image?: Maybe<MediaImageBlurredUrl90Fragment>;
  contentType?: Maybe<string>;
  section?: Maybe<string>;
  profession?: Maybe<string>;
  timeToRead?: Maybe<number>;
  buttonTitle: string;
  internalLink: string;
}

const ArticleCard: React.FC<Props> = ({
  title,
  author,
  image,
  contentType,
  section,
  profession,
  timeToRead,
  buttonTitle,
  internalLink,
}) => {
  const containerClass = 'grid grid-cols-1';

  return (
    <div className={containerClass}>
      <div className="space-y-5 laptop-standard:space-y-6 pb-10 laptop-standard:pb-16">
        <div className="w-full laptop-standard:w-12/12">
          <LinkTo to={internalLink} type="internal" accessibilityLabel={title ?? 'Read More'}>
            <AnimatedImage image={image} className="h-full w-full" animated />
          </LinkTo>
        </div>
        <p className="caption flex-shrink">
          {section} | {timeToRead} min read
        </p>
        <h6>
          <Link to={internalLink}>{title}</Link>
        </h6>
        <p className="caption flex-shrink">
          by {author}
          {!profession ? '' : `, ${profession}`}
        </p>
        <Button buttonTitle={buttonTitle} contentType={contentType} internalLink={internalLink} />
      </div>
    </div>
  );
};

export default ArticleCard;
