import React from 'react';
import { CompleteParagraphSpacerFieldsFragment } from '../../../graphql-types';

const Spacing = {
  'none': '0',
  'xs': '7',
  's': '13',
  'm': '17',
  'l': '26',
  'xl': '45',
};

const SpacingMobile = {
  'none': '0',
  'xs': '3',
  's': '7',
  'm': '13',
  'l': '17',
  'xl': '26',
};

interface Props {
  index: number;
  spacerParagraph: CompleteParagraphSpacerFieldsFragment;
}

const Spacer: React.FC<Props> = ({ spacerParagraph }) => {
  const spacing = spacerParagraph.field_spacing as keyof typeof Spacing;
  return <div className={`pb-${SpacingMobile[spacing]} laptop-standard:pb-${Spacing[spacing]}`} />;
};

export default Spacer;
export { Spacing, SpacingMobile };
