import React from 'react';

interface Props {
  videoURL: string;
  caption?: string;
  optionsAllowed?: string;
  containerStyle?: string;
  videoStyle?: string;
  fullScreen?: boolean;
}

const EmbeddedVideo: React.FC<Props> = (props) => {
  const {
    containerStyle = 'relative aspect-ratio-16/9 mt-18 laptop-standard:mt-27',
    videoStyle = 'absolute w-full h-full',
    optionsAllowed = 'autoplay; fullscreen',
    fullScreen,
    videoURL,
    caption,
  } = props;

  const iFrameExtra = {
    webkitallowfullscreen: fullScreen ? 'true' : '',
    mozallowfullscreen: fullScreen ? 'true' : '',
  };

  return (
    <>
      <div className={containerStyle}>
        <iframe
          title="modalVideo"
          className={videoStyle}
          src={videoURL}
          frameBorder="0"
          allow={optionsAllowed}
          allowFullScreen
          {...iFrameExtra}
        />
      </div>
      {caption && (
        <div className="pt-6 laptop-standard:pt-10 maxWidth padding-lateral">
          <p className="caption">{caption}</p>
        </div>
      )}
    </>
  );
};

export default EmbeddedVideo;
