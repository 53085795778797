import React from 'react';
import { CompleteParagraphComponentFragment } from '../../../graphql-types';
import AutoOrder from '../../pageBuilders/autoOrder';

interface Props {
  id: string;
  index: number;
  componentParagraph: CompleteParagraphComponentFragment;
}

const Component: React.FC<Props> = ({ id, index, componentParagraph }) => {
  const componentItem = componentParagraph.relationships?.field_component;
  switch (componentItem?.__typename) {
    case 'node__quick_li': {
      return <AutoOrder data={{ allNodeQuickLi: { nodes: [componentItem] } } as any} id={id} />;
    }
    default: {
      return null;
    }
  }
};

export default Component;
