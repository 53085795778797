import React, { Component, ReactNode } from 'react';
import Button from './button';
import ReactHtmlParser from 'react-html-parser';

interface Props {
  title: React.ReactElement<any, string | React.JSXElementConstructor<any>>[] | string;
  body?: React.ReactElement<any, string | React.JSXElementConstructor<any>>[] | string;
  buttonTitle: string;
  icon?: string;
  type?: string;
  videoId?: string;
  internalLink?: string;
  externalLink?: string;
  contentType: string;
  styles: string;
  bodyStyle?: string;
  containerStyle?: string;
  titleClassContainer: string;
}

class ContentLarge extends Component<Props> {
  render(): ReactNode {
    const body = this.props.body ? (
      <div className={this.props.bodyStyle ?? 'body1 text-white laptop-standard:w-2/3'}>
        {this.props.body}
      </div>
    ) : (
      <></>
    );
    return (
      <div className={`laptop-standard:w-6/12 ${this.props.containerStyle}`}>
        {this.props.type === 'landing' ? (
          <h4
            className={
              this.props.titleClassContainer ??
              'mobile:pb-7 laptop-standard:pb-11 text-white tracking-normal'
            }
          >
            {ReactHtmlParser(String(this.props.title))}
          </h4>
        ) : (
          <h2
            className={
              this.props.titleClassContainer ??
              'mobile:pb-7 laptop-standard:pb-13 text-white tracking-normal'
            }
          >
            {ReactHtmlParser(String(this.props.title))}
          </h2>
        )}
        {body}
        {this.props.children}
        <div className="mobile:pt-7 laptop-standard:pt-12">
          <Button
            buttonTitle={this.props.buttonTitle}
            icon={this.props.icon}
            styles={this.props.styles}
            contentType={this.props.contentType}
            internalLink={this.props.internalLink}
            externalLink={this.props.externalLink}
            videoId={this.props.videoId}
          />
        </div>
      </div>
    );
  }
}

export default ContentLarge;
