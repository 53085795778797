import React, { useState } from 'react';
import NewsItem from './newsItem';
import * as style from './newsGrid.module.css';
import Button from '../button';
import playSmallIcon from '../../images/icons/video.svg';
import linkIcon from '../../images/icons/external_link.svg';
import internalLinkIcon from '../../images/icons/internal_link.svg';
import DownArrow from '../../images/icons/DownArrow.svg';
import { graphql, useStaticQuery } from 'gatsby';
import {
  CompleteNewsFieldsNodeFragment,
  CompleteNewsGridFieldsFragment,
  GetLastNewsAndFeaturedQuery,
} from '../../../graphql-types';
import Container from '../container';

const GET_LAST_NEWS_AND_FEATURED = graphql`
  query GetLastNewsAndFeatured {
    allNodeNews(sort: { fields: [field_date], order: DESC }) {
      ...CompleteNewsFields
    }
    nodeFeaturedNews {
      relationships {
        field_news {
          ...CompleteNewsFieldsNode
        }
      }
    }
  }
`;

function newsFeaturedSort(
  nodes: GetLastNewsAndFeaturedQuery['allNodeNews']['nodes'],
  featuredNewsIDs: string[]
) {
  const featuredNews = featuredNewsIDs.reduce<CompleteNewsFieldsNodeFragment[]>(
    (result, featuredNewsID) => {
      const featuredNew = nodes.find((news) => news.id === featuredNewsID);
      if (featuredNew) {
        result.push(featuredNew);
      }
      return result;
    },
    []
  );

  const nonFeaturedNews = nodes.filter((news) => !featuredNewsIDs.includes(news.id));
  return [...featuredNews, ...nonFeaturedNews];
}

function isCurrentPageNews() {
  const newsURLpath = 'news';
  const currentURL = window.location.pathname ?? '';
  const URLpathArray = currentURL.split('/');
  if (
    URLpathArray[URLpathArray.length - 1] === newsURLpath ||
    URLpathArray[URLpathArray.length - 2] === newsURLpath
  ) {
    return true;
  }
  return false;
}

interface Props {
  config: CompleteNewsGridFieldsFragment['nodes'][0];
  className?: string;
}

const NewsGrid: React.FC<Props> = ({ config, className }) => {
  const {
    allNodeNews: { nodes },
    nodeFeaturedNews,
  } = useStaticQuery<GetLastNewsAndFeaturedQuery>(GET_LAST_NEWS_AND_FEATURED);
  const featuredNewsIDs =
    nodeFeaturedNews?.relationships?.field_news?.map((featuredNew) => featuredNew?.id ?? '') ?? [];
  const newsData = isCurrentPageNews() ? nodes : newsFeaturedSort(nodes, featuredNewsIDs);
  let loadedNewsData = [...newsData];

  const {
    title: newsTitle,
    'field_news_displayed': newsDisplayed,
    'field_news_columns': newsColumns,
    'field_display_type': displayType,
    'field_show_header': showHeader,
    'field_show_load_more_': showLoadMore,
  } = config;

  const newsDisplayedNumber = newsDisplayed ?? 0;

  const [newsToShow, setNewsToShow] = useState(newsDisplayedNumber);

  const isDisplayedCompact = displayType === 'compact';
  const hasFourColumns = newsColumns === '4-columns';
  const newsTitleClassName = !isDisplayedCompact
    ? `${style.bodyCaption} title-format-home`
    : 'second-format-text-news-title';
  const newsWrapperClass = !isDisplayedCompact ? 'mobile2up' : null;
  const title =
    newsTitle && showHeader ? (
      <div className="flex justify-between pb-13">
        <div className="text-2lg laptop-standard:text-4lg leading-3 font-black tracking-normal">
          {newsTitle}
        </div>
        {/* <div className="text-right">
          <Link
            to="/news"
            className="orange-link leading-normal font-black tracking-normal text-base"
          >
            View All
          </Link>
        </div> */}
      </div>
    ) : (
      <></>
    );
  const containerClass = hasFourColumns
    ? showLoadMore
      ? style.newsFourthColumnsLessBottom
      : style.newsFourthColumns
    : style.newsThreeColumns;
  if (newsDisplayed) {
    loadedNewsData = newsData.slice(0, newsToShow);
  }
  return (
    <Container className={`padding-lateral ${className}`}>
      <div>
        {title}
        <div className={containerClass}>
          {loadedNewsData.map((newsItem, index) => {
            const {
              title: newTitle,
              'field_date': fieldDate,
              'field_link': fieldLink,
              'field_content_type': fieldContentType,
              'field_button_title': fieldButtonTitle,
              relationships,
            } = newsItem;

            let icon = linkIcon;
            if (fieldContentType === 'internalLink') {
              icon = internalLinkIcon;
            } else if (fieldContentType === 'video') {
              icon = playSmallIcon;
            }

            return (
              <NewsItem
                key={`new-${index}`}
                title={newTitle}
                buttonTitle={fieldButtonTitle}
                image={relationships?.field_image}
                imgAlt={relationships?.field_image?.field_media_image?.alt}
                contentType={fieldContentType}
                link={fieldLink ?? ''}
                videoId={null}
                date={fieldDate}
                icon={icon}
                styles="font-normal"
                titleClassName={newsTitleClassName}
                newsContainerClassName={
                  newsWrapperClass ??
                  'space-y-5 laptop-standard:space-y-13 pb-10 laptop-standard:pb-0 lateral-margin-mobile'
                }
                hideButton={isDisplayedCompact}
              />
            );
          })}
        </div>
      </div>
      {newsToShow <= nodes.length && showLoadMore && (
        <Button
          contentType="loadItems"
          icon={DownArrow}
          displayButton={loadedNewsData.length <= newsToShow}
          handleClick={() => setNewsToShow((value) => value + newsDisplayedNumber)}
          linkTitle="Load More"
          styles="container mx-auto w-40 py-20"
        />
      )}
    </Container>
  );
};

export default NewsGrid;
