import React from 'react';
import RenderText from '../renderText';
import ReactHtmlParser from 'react-html-parser';
import { ToutTextProps } from '../props';

const ToutTextSimpleDuet: React.FC<ToutTextProps> = ({
  toutTextItem,
  backgroundClass,
  titleClass,
}) => {
  return (
    <RenderText
      className={backgroundClass}
      titleClassName={titleClass}
      title={
        toutTextItem.field_custom_title?.processed
          ? ReactHtmlParser(toutTextItem?.field_custom_title.processed ?? '')
          : toutTextItem.title
      }
      body={ReactHtmlParser(toutTextItem?.body?.processed ?? '')}
      fieldLink={toutTextItem.field_link}
      videoID={toutTextItem.field_video_id}
      contentType={toutTextItem.field_content_type}
      buttonTitle={toutTextItem.field_button_title}
      buttonType="text"
    />
  );
};

export default ToutTextSimpleDuet;
